import { BlockRenderer } from '@/builders/types';
import AsideComposite from '@/components/ui/Block/Article/AsideComposite';
import VersionSwitch from '@/components/ui/Block/Navigation/VersionSwitch';
import { Universe } from '@/types/documents';
import { TabTemplate } from '@/types/model';

const type = 'version-switch';

export type ArticleVersionSwitchBlockRenderer = {
  toUniverse: Universe;
  urls: {
    [key: string]: string;
  };
  template?: TabTemplate;
};

function render(props: ArticleVersionSwitchBlockRenderer): NonNullable<React.ReactNode> {
  return (
    <AsideComposite>
      <VersionSwitch {...props} />
    </AsideComposite>
  );
}

export const ArticleVersionSwitchBlockRenderer: BlockRenderer = {
  type,
  render,
};
