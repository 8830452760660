import { BlockRenderer } from '@/builders/types';
import EraHeader from '@/components/ui/Block/Chronology/EraHeader';
import { Era } from '@/utils/references/eras';

const type = 'era-header';

export type EraHeaderBlockProps = {
  era: Era;
};

function render(props: EraHeaderBlockProps): NonNullable<React.ReactNode> {
  return <EraHeader {...props} />;
}

export const EraHeaderBlockRenderer: BlockRenderer = {
  type,
  render,
};
