import { trimSentence } from '@/utils/helpers';
import classNames from 'classnames';
import { Fragment, useState } from 'react';
import classes from './Description.module.css';

interface Props {
  content: string;
  useBold?: boolean;
  isSticked?: boolean;
  trimAt?: number;
  allowExpand?: boolean;
  type?: string;
}

function Description(props: Props) {
  const { useBold, isSticked, content, trimAt, allowExpand, type } = props;
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const typeNode = type ? <span className={classes.type}>{type}</span> : <Fragment />;
  // Reduce display if no extend requested, there is a trim required and the trimed text is at least twice the initial displayed length.
  // It prevents a "See more" link displaying only a few words more.
  const displayAsReduced = !isExpanded && trimAt && content.length > trimAt * 2;

  return (
    <Fragment>
      <div
        className={classNames(classes.description, {
          [classes.highlighted]: useBold === true,
          [classes.noBottom]: isSticked === true,
        })}
      >
        {!displayAsReduced && (
          <Fragment>
            {typeNode}
            {content}
          </Fragment>
        )}
        {displayAsReduced && (
          <Fragment>
            {trimSentence(content, type ? trimAt - type.length : trimAt)}
            {allowExpand && (
              <a className={classes.expandLink} onClick={() => setIsExpanded(true)}>
                Lire la suite
              </a>
            )}
          </Fragment>
        )}
      </div>
    </Fragment>
  );
}

export default Description;
