import classNames from 'classnames';
import { ReactNode } from 'react';
import classes from './GridList.module.css';

type Props = {
  children: ReactNode;
  template?: 'large' | 'small';
};

function GridList(props: Props) {
  return (
    <div className={classNames(classes.container, { [classes.large]: props.template && props.template === 'large' })}>
      {props.children}
    </div>
  );
}

export default GridList;
