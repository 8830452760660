import { getBlockComponent } from '@/builders/blocks/renderer/blockRenderer';
import { CompositeBlockProps } from '@/builders/blocks/renderers';
import { BlockRenderer } from '@/builders/types';
import SpotlightsComposite from '@/components/ui/Block/Spotlight/SpotlightsComposite';
import { Fragment } from 'react';

const type = 'spotlights-composite';

function render(props: CompositeBlockProps): NonNullable<React.ReactNode> {
  return (
    <SpotlightsComposite>
      {props.blockDefinitions.map((blockDefinition, index) => {
        return <Fragment key={index}>{getBlockComponent(blockDefinition)}</Fragment>;
      })}
    </SpotlightsComposite>
  );
}

export const SpotlightsCompositeBlockRenderer: BlockRenderer = {
  type,
  render,
};
