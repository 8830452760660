function NewTabIcon() {
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.25 14.75H3.75V4.25H9V2.75H2.25V16.25H15.75V9.5H14.25V14.75ZM10.5 2.75V4.25H13.1925L5.82 11.6225L6.8775 12.68L14.25 5.3075V8H15.75V2.75H10.5Z"
        fill="#000"
      />
    </svg>
  );
}

export default NewTabIcon;
