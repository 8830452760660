import { MetadataBlockProps } from '@/builders/blocks/Article/renderers';
import { useEffect, useState } from 'react';
import classes from './Metadata.module.css';

function Metadata(props: MetadataBlockProps) {
  const { author, lastUpdatedAt } = props;
  const [displayedDate, setDisplayedDate] = useState<string>(new Date(lastUpdatedAt).toDateString());

  useEffect(() => {
    // Format the date to the client locale
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = new Intl.DateTimeFormat(navigator.language, options).format(new Date(lastUpdatedAt));

    setDisplayedDate(formattedDate);
  }, [lastUpdatedAt]);

  return (
    <section className={classes.container}>
      <div>
        Rédigé par <b>{author}</b>
      </div>
      <div>Dernière mise à jour le {displayedDate}</div>
    </section>
  );
}

export default Metadata;
