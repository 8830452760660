import { BlockRenderer } from '@/builders/types';
import EraEntryList from '@/components/ui/Block/List/EraEntryList';
import { DatedItem } from '@/types/model';

const type = 'era-entry-list';

export interface EraEntryListBlockProps {
  items: Array<DatedItem>;
}

function render(props: EraEntryListBlockProps): NonNullable<React.ReactNode> {
  return <EraEntryList {...props} />;
}

export const EraEntryListBlockRenderer: BlockRenderer = {
  type,
  render,
};
