import { BlockRenderer } from '@/builders/types';
import GridEntryList from '@/components/ui/Block/List/GridEntryList';
import { EntryListItem, Pager } from '@/types/model';

const type = 'grid_entry_list';

export type GridEntryListBlockProps = {
  title?: string;
  pager: Pager<EntryListItem>;
};

function render(props: any): NonNullable<React.ReactNode> {
  return <GridEntryList {...props} />;
}

export const GridEntryListBlockRenderer: BlockRenderer = {
  type,
  render,
};
