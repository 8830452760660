function AffiliationIcon() {
  return (
    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M5.35996 2C5.35996 2.25333 5.45996 2.48 5.61663 2.65333L4.23329 5.03C4.15663 5.01333 4.07996 5 3.99996 5C3.91996 5 3.84329 5.01333 3.76663 5.03L2.39663 2.68C2.56329 2.5 2.66663 2.26333 2.66663 2C2.66663 1.44667 2.21996 1 1.66663 1C1.11329 1 0.666626 1.44667 0.666626 2C0.666626 2.55333 1.11329 3 1.66663 3C1.74663 3 1.82329 2.98667 1.89996 2.97L3.26996 5.32C3.10329 5.5 2.99996 5.73667 2.99996 6C2.99996 6.55333 3.44663 7 3.99996 7C4.55329 7 4.99996 6.55333 4.99996 6C4.99996 5.73667 4.89663 5.5 4.72996 5.32L6.11663 2.94667C6.18663 2.96333 6.25996 2.97333 6.33329 2.97333C6.86996 2.97333 7.30663 2.53667 7.30663 2C7.30663 1.46333 6.86996 1.02667 6.33329 1.02667C5.79663 1.02667 5.35996 1.46333 5.35996 2Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="8" height="8" fill="white" transform="translate(0 8) rotate(-90)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default AffiliationIcon;
