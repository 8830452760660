import { BlockRenderer } from '@/builders/types';
import MainPicture from '@/components/ui/Block/Article/MainPicture';
import { Picture, PictureFormat } from '@/types/model';
import { Fragment } from 'react';

const type = 'main-picture';

export type MainPictureBlockProps = {
  picture?: Picture;
  pictureFormat?: PictureFormat;
  title: string;
};

function render(props: MainPictureBlockProps): NonNullable<React.ReactNode> {
  if (props.picture === null) {
    return <Fragment />;
  }

  return <MainPicture {...props} />;
}

export const MainPictureBlockRenderer: BlockRenderer = {
  type,
  render,
};
