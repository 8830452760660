import { UniverseByTypeListBlockProps } from '@/builders/blocks/UniverseByTypeListBlockRenderer';
import { BlockRenderer } from '@/builders/types';
import UniverseByTypeList from '@/components/ui/Block/List/UniverseByTypeList';

const type = 'row_universe_by_tag_list';

function render(props: UniverseByTypeListBlockProps): NonNullable<React.ReactNode> {
  return <UniverseByTypeList {...props} />;
}

export const UniverseByTagListBlockRenderer: BlockRenderer = {
  type,
  render,
};
