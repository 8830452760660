import { BlockRenderer } from '@/builders/types';
import LeftArrowIcon from '@/components/icons/LeftArrowIcon';
import Button from '@/components/ui/Button/Button';

const type = 'button';

export type BackButtonBlockProps = {
  content: string;
  href: string | object;
  primary?: boolean;
  alternate?: boolean;
  faded?: boolean;
};

function render(props: BackButtonBlockProps): NonNullable<React.ReactNode> {
  return (
    <Button faded={props.faded} alternate={props.alternate} primary={props.primary} href={props.href}>
      <LeftArrowIcon />
      {props.content}
    </Button>
  );
}

export const BackButtonBlockRenderer: BlockRenderer = {
  type,
  render,
};
