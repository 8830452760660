import { DatedItem } from '@/types/model';
import { generateUrl } from '@/utils/pictureUrlGenetator';
import eras from '@/utils/references/eras';
import Link from 'next/link';
import classes from './ChronologyItem.module.css';

interface Props {
  item: DatedItem;
}

function ChronologyItem(props: Props) {
  const { item } = props;

  return (
    <li className={classes.item}>
      <Link href={item.url} className={classes.link}>
        <span className={classes.timeline}>
          <span className={classes.emblem}>{item.eraId ? eras.getEraIcon(item.eraId) : null}</span>
        </span>
        <span className={classes.content}>
          <span className={classes.title}>{item.name}</span>
          <span className={classes.description}>{item.description || ''}</span>
        </span>
      </Link>
      {item.image && (
        <div className={`${classes.image}`} style={{ backgroundImage: `url(${generateUrl(item.image, 'page') || ''}` }}>
          <Link href={item.url} className={`${classes.link}`}>
            <span className={classes.timeline}>
              <span className={classes.line}></span>
            </span>
            <span className={classes.content}></span>
          </Link>
        </div>
      )}
    </li>
  );
}

export default ChronologyItem;
