import { BlockRenderer } from '@/builders/types';
import Spotlight from '@/components/ui/Block/Spotlight/Spotlight';
import { EntryListItem, Picture, UrlDefinition } from '@/types/model';

const type = 'spotlight';

export type SpotlightBlockProps = {
  title: string;
  logo?: Picture;
  background: Picture;
  items: Array<EntryListItem>;
  label?: string;
  target?: UrlDefinition;
};

function render(props: SpotlightBlockProps): NonNullable<React.ReactNode> {
  return <Spotlight {...props} />;
}

export const SpotlightBlockRenderer: BlockRenderer = {
  type,
  render,
};
