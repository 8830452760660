import { SingleEntrySpotlightBlockProps } from '@/builders/blocks/renderers';
import Button from '@/components/ui/Button/Button';
import Gradient from '@/components/ui/Effect/Gradient';
import useIsUnderMediaQuery from '@/hooks/useMediaQuery';
import { PictureFormat } from '@/types/model';
import { GtmAction, GtmCategory, GtmFireEvent } from '@/utils/gtm';
import { trimSentence } from '@/utils/helpers';
import { generateUrl } from '@/utils/pictureUrlGenetator';
import Image from 'next/legacy/image';
import classes from './SingleEntrySpotlight.module.css';

function SingleEntrySpotlight(props: SingleEntrySpotlightBlockProps) {
  const { title, entry } = props;

  const isMobile = useIsUnderMediaQuery(768);

  return (
    <Gradient
      backgroundImage={entry.image ? generateUrl(entry.image, 'square') : ''}
      gradientClass="singleEntrySpotlight"
      imageTitle={entry.name}
    >
      <section className={classes.container}>
        <div className={classes.content}>
          <span className={classes.subtitle}>{title ? title : 'Sélection du jour'}</span>
          <h1 className={classes.title}>{entry.name}</h1>
          <p className={classes.description}>{trimSentence(entry.description, isMobile ? 150 : 500)}</p>
          <div className={classes.button}>
            <Button
              href={`/encyclopedie/${entry.slug}`}
              onClickHandler={() => GtmFireEvent(GtmCategory.CTAClick, GtmAction.SingleEntrySpotlight, entry.name)}
            >
              En savoir plus
            </Button>
          </div>
        </div>
        {entry.image && (
          <div className={classes.image}>
            <Image
              src={generateUrl(entry.image, PictureFormat.Square)}
              alt={entry.name}
              width={entry.image.sizes[PictureFormat.Square].width}
              height={entry.image.sizes[PictureFormat.Square].height}
            />
          </div>
        )}
      </section>
    </Gradient>
  );
}

export default SingleEntrySpotlight;
