import useSizeElement from '@/hooks/useSizeElement';
import { Children, Fragment, ReactNode } from 'react';
import { FreeMode, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import classes from './RowList.module.css';

type Props = {
  children: ReactNode;
  useAlternateStyle?: boolean;
};

function RowList(props: Props) {
  const { children } = props;
  const swiperSize = useSizeElement();

  return (
    <div className={classes.container} ref={swiperSize.elementRef}>
      <Swiper
        slidesPerView={'auto'}
        slidesPerGroup={Math.floor(swiperSize.width / 150)}
        freeMode={true}
        navigation={true}
        modules={[FreeMode, Navigation]}
      >
        {Children.map(children, (child, index) =>
          child ? <SwiperSlide key={index}>{child}</SwiperSlide> : <Fragment></Fragment>,
        )}
      </Swiper>
    </div>
  );
}

export default RowList;
