function HighRepublicEraIcon() {
  return (
    <svg width="150" height="150" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M74.853 35.4307C74.5301 35.8123 69.3051 70.5676 67.3383 85.5676C66.7219 90.2643 66.7806 90.5285 69.8921 94.9023C73.0624 99.3935 73.2385 99.8044 73.6788 103.532C74.0898 107.319 74.7649 111.311 74.9998 111.575C75.4988 112.075 75.9098 110.02 76.7317 103.004L77.1426 99.6283L79.7845 95.8416C81.2229 93.7868 82.6906 91.5559 83.0428 90.9101L83.6299 89.7359L80.4303 67.5735C76.2033 38.3074 75.7923 35.5187 75.4988 35.2252C75.352 35.0784 75.0585 35.1665 74.853 35.4307Z"
        fill="black"
      />
      <path
        d="M45.9097 49.638C40.7727 52.1625 39.305 53.3953 38.0721 56.1253C37.1328 58.1801 37.1915 61.9374 38.1895 64.0803C38.9527 65.6947 43.6494 73.8846 55.3324 93.9922C61.9664 105.411 63.1993 107.671 63.0525 108.141C62.9351 108.493 60.1758 105.323 54.7453 98.5421C52.6611 95.9589 49.9606 92.6419 48.7277 91.2036C46.1739 88.1801 35.2834 74.9413 32.9351 72.0059C29.4713 67.6908 28.5907 64.4619 29.4713 59.5304C29.7061 58.092 29.8529 56.8004 29.7649 56.683C29.5007 56.2427 27.7688 57.7985 24.8921 60.9981C21.3989 64.9609 20.7825 66.1351 20.6063 69.1879C20.4302 72.4756 21.1641 74.3542 23.6005 76.7906C24.5692 77.7887 31.35 83.5421 38.6005 89.5891C55.2737 103.444 61.35 108.64 61.35 108.992C61.35 109.755 63.3461 110.9 25.0975 88.1801C16.2032 82.8963 14.6181 81.1057 13.62 75.411C13.121 72.4756 13.0036 72.182 12.5926 72.4462C12.123 72.7398 9.95077 77.4364 8.80595 80.636C7.98403 82.9257 7.89597 83.5421 8.01339 85.2447C8.30693 88.8259 10.2443 91.7319 13.3559 93.3171C14.1778 93.728 18.5809 95.4306 23.1895 97.0744C35.4596 101.507 55.5966 108.787 58.033 109.697C59.2071 110.137 60.3226 110.695 60.5281 110.959C61.1445 111.663 62.8471 111.986 24.3637 104.677C17.9938 103.474 12.123 102.27 11.3011 102.006C8.39499 101.067 6.31084 99.3347 4.52024 96.3993C3.75703 95.1664 2.93511 93.9041 2.67092 93.6106C2.25996 93.1116 2.23061 93.1116 1.93707 93.6106C1.76094 93.9041 1.58482 96.6341 1.52611 99.8043L1.40869 105.47L2.23061 107.084C3.1993 108.992 4.9312 110.695 7.01535 111.751L8.51241 112.485L36.6925 112.427C61.35 112.339 64.9018 112.28 65.2835 111.898C65.8412 111.37 65.5476 110.489 52.0153 72.1233C47.9058 60.3816 47.7296 59.8239 47.9058 56.3601C48.0525 53.4834 48.4048 52.456 50.4889 49.1977C51.2815 47.9355 51.3989 47.319 50.8412 47.319C50.7238 47.319 48.5222 48.3758 45.9097 49.638Z"
        fill="black"
      />
      <path
        d="M99.3931 47.5832C99.305 47.7006 99.8921 49.0803 100.655 50.6654C101.477 52.2799 102.24 54.3053 102.446 55.3915C103.062 58.6498 103.238 58.0627 94.168 83.7183C89.0898 98.1605 84.8334 110.783 84.8334 111.458C84.8334 112.045 85.0389 112.221 85.9489 112.397C86.5359 112.515 99.4224 112.603 114.569 112.573L142.103 112.515L143.63 111.634C145.538 110.548 147.094 108.963 148.062 107.084C148.796 105.705 148.826 105.44 148.826 100.92C148.826 93.0235 148.415 91.6145 146.947 94.5206C145.098 98.1899 142.485 100.685 139.315 101.889C138.493 102.182 129.951 103.914 120.352 105.705C110.753 107.525 100.567 109.462 97.7492 109.99C91.6729 111.164 88.943 111.517 89.3833 111.076C89.8823 110.548 92.1132 109.726 123.874 98.2486C130.89 95.6948 137.055 93.3758 137.554 93.0823C138.933 92.231 140.871 90.0294 141.575 88.4736C142.045 87.4169 142.192 86.5069 142.192 84.5989C142.192 82.3973 142.045 81.7809 141.076 79.3151C139.843 76.2916 138.552 73.6498 137.876 72.7104C137.348 72.0059 137.377 71.9472 136.673 75.4697C136.409 76.82 135.851 78.6987 135.44 79.638C134.148 82.4854 132.24 83.9825 122.465 89.7946C102.974 101.36 96.3402 105.294 94.3735 106.468C91.8197 108.024 89.3539 109.256 88.9136 109.256C88.2678 109.256 89.6475 108.024 102.886 96.9864C128.894 75.2643 128.101 75.9981 129.217 73.0333C130.215 70.4502 129.863 66.8396 128.454 64.638C126.781 62.0842 120.91 56.2134 120.381 56.5656C120.235 56.6243 120.381 57.7985 120.675 59.1194C121.379 62.231 121.379 63.8455 120.675 66.0764C119.706 69.1879 120.205 68.5421 99.6866 93.5226C93.258 101.389 87.8569 107.877 87.7101 107.965C87.0937 108.346 87.1817 107.759 87.9449 106.262C88.3559 105.411 93.2874 96.8396 98.8647 87.2408C111.428 65.6067 111.164 66.047 112.221 63.8161C113.043 62.0549 113.072 61.82 112.896 59.5597C112.485 54.8337 110.518 52.4267 104.853 49.7554C100.215 47.5832 99.5399 47.319 99.3931 47.5832Z"
        fill="black"
      />
    </svg>
  );
}

export default HighRepublicEraIcon;
