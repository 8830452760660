import { CardBlockProps } from '@/builders/blocks/Article/renderers';
import CardSection from '@/components/ui/Card/CardSection';
import { Fragment } from 'react';

function Card(props: CardBlockProps) {
  const { sections } = props;

  return (
    <Fragment>
      {sections.map((section, index) => (
        <CardSection key={index} section={section} />
      ))}
    </Fragment>
  );
}

export default Card;
