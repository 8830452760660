import { EntryListBlockProps } from '@/builders/blocks/renderers';
import GridList from '@/components/ui/List/GridList';
import CardItem from '@/components/ui/List/Item/CardItem';
import CardSeeMoreItem from '@/components/ui/List/Item/CardSeeMoreItem';
import RowList from '@/components/ui/List/RowList';
import SectionTitle from '@/components/ui/Title/SectionTitle';
import useIsUnderMediaQuery from '@/hooks/useMediaQuery';
import { ListTemplate } from '@/types/model';
import { GtmAction, GtmCategory, GtmFireEvent } from '@/utils/gtm';
import classes from './EntryList.module.css';

function EntryList(props: EntryListBlockProps) {
  const { title, items, limit } = props;

  const isMobile = useIsUnderMediaQuery(768);
  const template = isMobile ? ListTemplate.Row : ListTemplate.Grid;

  // Do not add a plus link for less than 3 elements
  const useLimit = limit && items.length > limit.count;
  const moreNumber = useLimit ? items.length - limit.count : null;
  const limitedItems = useLimit ? items.slice(0, limit.count - 1) : items;

  // Elements array must be created before to prevent the slider
  // to have an empty node due to the see more condition
  const elements = limitedItems.map((item, index) => (
    <CardItem
      key={`${item.slug}${item.version}`}
      title={item.name}
      href={`/encyclopedie/${item.slug}`}
      subtitle={item.type.name}
      picture={item.image}
      allowPictureScale={template === 'grid'}
      onClickHandler={() => GtmFireEvent(GtmCategory.CTAClick, GtmAction.EntryList, title, (index + 1).toString())}
    />
  ));

  if (moreNumber) {
    elements.push(<CardSeeMoreItem key={'seemore'} title={`Voir les autres articles`} href={limit.url} />);
  }

  return (
    <section className={classes.container}>
      {title && <SectionTitle>{title}</SectionTitle>}
      {template === ListTemplate.Row && <RowList>{elements}</RowList>}
      {template === ListTemplate.Grid && <GridList>{elements}</GridList>}
    </section>
  );
}

export default EntryList;
