import { VersionSwitchBlockProps } from '@/builders/blocks/renderers';
import TabContainer from '@/components/ui/Tab/TabContainer';
import TabItem from '@/components/ui/Tab/TabItem';
import { Universe } from '@/types/documents';

function VersionSwitch(props: VersionSwitchBlockProps) {
  const { urls, toUniverse, template } = props;

  return (
    <TabContainer template={template}>
      <TabItem title="Univers Canon" isActive={toUniverse === Universe.Legends} href={urls[Universe.Canon]} />
      <TabItem title="Univers Légendes" isActive={toUniverse === Universe.Canon} href={urls[Universe.Legends]} />
    </TabContainer>
  );
}

export default VersionSwitch;
