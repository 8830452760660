import Button from '@/components/ui/Button/Button';
import Gradient from '@/components/ui/Effect/Gradient';
import { HighlightItem, PictureFormat } from '@/types/model';
import { GtmAction, GtmCategory, GtmFireEvent } from '@/utils/gtm';
import { generateUrl } from '@/utils/pictureUrlGenetator';
import { resolveUrl } from '@/utils/routes';
import Image from 'next/legacy/image';
import Link from 'next/link';
import classes from './HighlightTile.module.css';

type Props = {
  item: HighlightItem;
};

function HighlightTile(props: Props) {
  const { item } = props;

  return (
    <Gradient
      gradientClass="highlights"
      backgroundImage={item.config?.image ? generateUrl(item.config?.image, PictureFormat.Page) : null}
      hasImagePriority={true}
      imageTitle={item.config.label}
    >
      <section className={classes.container}>
        {item.config?.logo && (
          <div className={classes.logo}>
            <Link
              href={resolveUrl(item.config.routeConfig)}
              onClick={() => GtmFireEvent(GtmCategory.CTAClick, GtmAction.CarouselSpotlight, item.config.label)}
            >
              <Image
                src={generateUrl(item.config.logo, PictureFormat.Page)}
                width={item.config.logo.sizes[PictureFormat.Page].width}
                height={item.config.logo.sizes[PictureFormat.Page].height}
                layout="responsive"
                alt={item.config.label}
              />
            </Link>
          </div>
        )}
        <Button
          alternate={true}
          href={resolveUrl(item.config.routeConfig)}
          onClickHandler={() => GtmFireEvent(GtmCategory.CTAClick, GtmAction.CarouselSpotlight, item.config.label)}
        >
          {item.config.label}
        </Button>
      </section>
    </Gradient>
  );
}

export default HighlightTile;
