import { GridSourceListBlockProps } from '@/builders/blocks/renderers';
import LoaderIcon from '@/components/icons/LoaderIcon';
import GridList from '@/components/ui/List/GridList';
import CardItem from '@/components/ui/List/Item/CardItem';
import SectionTitle from '@/components/ui/Title/SectionTitle';
import { useLoadItems } from '@/hooks/useLoadItems';
import { PictureFormat, SourceListItem } from '@/types/model';
import { GtmAction, GtmCategory, GtmFireEvent } from '@/utils/gtm';
import { getPathToSource } from '@/utils/routes';
import { getSources } from 'api/content/source';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import classes from './GridSourceList.module.css';

function GridSourceList(props: GridSourceListBlockProps) {
  const { title, pager, pictureRatio } = props;
  const onItemLoaded = (count: number) => {
    GtmFireEvent(GtmCategory.InfiniteScroll, GtmAction.GridSourceList, title, count.toString());
  };
  const { loading, items, hasNextPage, error, loadMore } = useLoadItems<SourceListItem>(
    getSources,
    pager,
    onItemLoaded,
  );

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: loadMore,
    disabled: !!error,
    rootMargin: '0px 0px 400px 0px',
  });

  return (
    <section className={classes.container}>
      {title && <SectionTitle>{title}</SectionTitle>}
      <GridList template="large">
        {items.map((item) => (
          <CardItem
            key={`${item.slug}`}
            title={item.name}
            href={getPathToSource(item)}
            subtitle={
              item.releasePeriod ??
              (item.firstReleasedAt ? new Date(item.firstReleasedAt).getFullYear().toString() : 'Sortie à venir')
            }
            picture={item.image}
            pictureFormat={PictureFormat.Page}
            pictureRatio={pictureRatio}
          />
        ))}
      </GridList>
      {(loading || hasNextPage) && (
        <div className={classes.loader} ref={sentryRef}>
          <LoaderIcon />
        </div>
      )}
    </section>
  );
}

export default GridSourceList;
