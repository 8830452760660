import { GtmAction, GtmCategory, GtmFireEvent } from '@/utils/gtm';
import Image from 'next/legacy/image';
import Link from 'next/link';
import { Fragment, MouseEvent as ME } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkUnwrapImages from 'remark-unwrap-images';
import classes from './MarkdownContent.module.css';

type Props = {
  children: string;
  defaultImageTitle?: string;
  onLinkClick?: (e: ME<HTMLAnchorElement, MouseEvent>, href: string) => void;
  onImageClick?: (e: ME<HTMLAnchorElement, MouseEvent>, id: number) => void;
};

function MarkdownContent(props: Props) {
  const { onLinkClick, onImageClick, defaultImageTitle } = props;
  // const refs = [];
  // const [paragraphsByTitle, setParagraphsByTitle] = useState(new Map());

  // const toggleParagraphs = (ref: any) => {
  //   if (!ref.current) {
  //     return;
  //   }

  //   // When a title is clicked, all related paragraphs are toggled
  //   paragraphsByTitle.get(ref.current.innerText).forEach((element) => {
  //     element.classList.toggle(classes.paragraphHidden);
  //     element.classList.toggle(classes.paragraphVisible);
  //   });

  //   // Title arrows are inversed
  //   ref.current.querySelectorAll(".arrow").forEach((arrowElement) => {
  //     arrowElement.classList.toggle(classes.opened);
  //     arrowElement.classList.toggle(classes.closed);
  //   });
  // };

  const createTitle = ({ ...props }) => {
    // For every title converted from markdown, a ref is created and stored
    // const ref = useRef();
    // refs.push(ref);

    return (
      <h2
        className={classes.title}
        // ref={ref}
        // onClick={() => toggleParagraphs(ref)}
      >
        <span className={classes.titleContent}>{props.children}</span>
        {/* <span className={`${classes.closed} arrow`}>
          <ArrowDown />
        </span> */}
      </h2>
    );
  };

  const createSubTitle = ({ ...props }) => {
    return <h3 className={classes.subtitle}>{props.children}</h3>;
  };

  // useEffect(() => {
  //   // When component fully loaded
  //   const refElements = refs.map((ref) => ref.current);
  //   // Iterate over all title refs
  //   refs.forEach((ref) => {
  //     const paragraphs = [];
  //     let element = ref.current;
  //     // Retrieve and store in a map all next paragraphs until next title (next stored ref)
  //     while (element.nextSibling !== null) {
  //       const nextSibling = element.nextSibling;
  //       if (refElements.includes(nextSibling)) {
  //         // Next element is a title ref, breaking
  //         break;
  //       }

  //       element = nextSibling;
  //       if (!element.classList) {
  //         // Next element is not a dom element, continuing
  //         continue;
  //       }

  //       paragraphs.push(element);

  //       // Paragraph of title are hidden by default
  //       // element.classList.add(classes.paragraphHidden);
  //     }
  //     paragraphsByTitle.set(ref.current.innerText, paragraphs);
  //   });
  //   setParagraphsByTitle(paragraphsByTitle);
  // }, [props.children, paragraphsByTitle, refs]);

  const createLink = ({ ...props }) => {
    const prop: any = props;
    const href = prop.href[0] === '/' ? prop.href : `/${prop.href}`;
    const trackClick = () =>
      GtmFireEvent(GtmCategory.CTAClick, GtmAction.ArticleContentLink, props.children.toString());
    return (
      <Fragment>
        {onLinkClick && (
          <Link
            href={href}
            onClick={(e) => {
              trackClick();
              onLinkClick(e, href);
            }}
          >
            {prop.children}
          </Link>
        )}
        {!onLinkClick && (
          <Link href={href} onClick={trackClick}>
            {prop.children}
          </Link>
        )}
      </Fragment>
    );
  };

  const createImg = ({ ...props }) => {
    const prop: any = props;
    const alt = prop.alt;

    let metadata;
    try {
      metadata = JSON.parse(alt) || {};
    } catch (e) {
      console.log(e);
    }

    return (
      <div className={classes.image}>
        <a
          href="#"
          onClick={(e) => {
            if (metadata && metadata.id) {
              onImageClick(e, parseInt(metadata.id, 10));
            }
          }}
        >
          <Image
            src={prop.src}
            width={metadata.width}
            height={metadata.height}
            alt={metadata.description ?? defaultImageTitle}
          />
        </a>
      </div>
    );
  };

  const createBlockquote = ({ children }) => {
    return <blockquote className={classes.blockquote}>{children}</blockquote>;
  };

  return (
    <ReactMarkdown
      rehypePlugins={[rehypeRaw]}
      remarkPlugins={[remarkUnwrapImages]}
      components={{
        h2: createTitle,
        h3: createSubTitle,
        a: createLink,
        img: createImg,
        blockquote: createBlockquote,
      }}
    >
      {props.children}
    </ReactMarkdown>
  );
}

export default MarkdownContent;
