import { BlockRenderer } from '@/builders/types';
import Affiliations from '@/components/ui/Block/Article/Affiliations';
import { EntryListItem } from '@/types/model';

const type = 'affiliations';

export type AffiliationsBlockProps = {
  entries: Array<EntryListItem>;
};

function render(props: AffiliationsBlockProps): NonNullable<React.ReactNode> {
  return <Affiliations {...props} />;
}

export const AffiliationsBlockRenderer: BlockRenderer = {
  type,
  render,
};
