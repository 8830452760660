import { HighlightBlockProps } from '@/builders/blocks/renderers';
import HighlightTile from '@/components/ui/Block/Highlight/HighlightTile';
import { Carousel } from 'react-responsive-carousel';
import classes from './Highlights.module.css';

function Highlights(props: HighlightBlockProps) {
  const { highlights } = props;
  return (
    <div className={classes.container}>
      <Carousel
        showStatus={false}
        showArrows={false}
        showThumbs={false}
        autoPlay={false}
        infiniteLoop={true}
        swipeable={true}
        interval={5000}
        stopOnHover={true}
      >
        {highlights.map((highlight, index) => (
          <HighlightTile key={`highlight-${index}`} item={highlight} />
        ))}
      </Carousel>
    </div>
  );
}

export default Highlights;
