import { BlockRenderer } from '@/builders/types';
import Breadcrumb from '@/components/ui/Block/Navigation/Breadcrumb';
import { BreadcrumbItem } from '@/types/model';

const type = 'breadrcrumb';

export type BreadcrumbBlockProps = {
  breadcrumb: Array<BreadcrumbItem>;
};

function render(props: BreadcrumbBlockProps): NonNullable<React.ReactNode> {
  return <Breadcrumb {...props} />;
}

export const BreadcrumbBlockRenderer: BlockRenderer = {
  type,
  render,
};
