import { UniverseByTypeListBlockProps } from '@/builders/blocks/UniverseByTypeListBlockRenderer';
import { BlockRenderer } from '@/builders/types';
import UniverseBySourceTypeList from '@/components/ui/Block/List/UniverseBySourceTypeList';

const type = 'row_universe_by_source_type_list';

function render(props: UniverseByTypeListBlockProps): NonNullable<React.ReactNode> {
  return <UniverseBySourceTypeList {...props} />;
}

export const UniverseBySourceTypeListBlockRenderer: BlockRenderer = {
  type,
  render,
};
