import { ReactNode } from 'react';
import classes from './Overlay.module.css';

type Props = {
  children: ReactNode;
  onClose: CallableFunction;
};

function Overlay(props: Props) {
  const { onClose, children } = props;

  return (
    <div className={classes.overlay} onClick={() => onClose()}>
      {children}
    </div>
  );
}

export default Overlay;
