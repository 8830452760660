import { GridEntryListBlockProps } from '@/builders/blocks/renderers';
import LoaderIcon from '@/components/icons/LoaderIcon';
import GridList from '@/components/ui/List/GridList';
import CardItem from '@/components/ui/List/Item/CardItem';
import SectionTitle from '@/components/ui/Title/SectionTitle';
import { useLoadItems } from '@/hooks/useLoadItems';
import { EntryListItem } from '@/types/model';
import { GtmAction, GtmCategory, GtmFireEvent } from '@/utils/gtm';
import { getEntries } from 'api/content/entry';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import classes from './GridEntryList.module.css';

function GridEntryList(props: GridEntryListBlockProps) {
  const { title, pager } = props;
  const onItemLoaded = (count: number) => {
    GtmFireEvent(GtmCategory.InfiniteScroll, GtmAction.GridSourceList, title, count.toString());
  };
  const { loading, items, hasNextPage, error, loadMore } = useLoadItems<EntryListItem>(getEntries, pager, onItemLoaded);

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: loadMore,
    disabled: !!error,
    rootMargin: '0px 0px 400px 0px',
  });

  return (
    <section className={classes.container}>
      {title && <SectionTitle>{title}</SectionTitle>}
      <GridList>
        {items.map((item) => (
          <CardItem
            key={`${item.slug}${item.version}`}
            title={item.name}
            href={`/encyclopedie/${item.slug}`}
            subtitle={item.type.name}
            picture={item.image}
          />
        ))}
      </GridList>
      {(loading || hasNextPage) && (
        <div className={classes.loader} ref={sentryRef}>
          <LoaderIcon />
        </div>
      )}
    </section>
  );
}

export default GridEntryList;
