import { ReactNode } from 'react';
import classes from './CardContainer.module.css';

type Props = {
  children: ReactNode;
};

function CardContainer(props: Props) {
  const { children } = props;

  return <div className={classes.container}>{children}</div>;
}

export default CardContainer;
