import { SourceHeaderBlockProps } from '@/builders/blocks/renderers';
import Description from '@/components/ui/Text/Description';
import { Fragment } from 'react';

function SourceHeader(props: SourceHeaderBlockProps) {
  const { type, description, firstReleasedAt, period } = props;
  const verb = type.releaseVerb;
  const periodPart = period ? ` ${verb} entre ${period}` : null;
  const datePart = firstReleasedAt ? ` ${verb} en ${new Date(firstReleasedAt).getFullYear()}` : null;
  const title = `${type.nameSingular}${periodPart ?? datePart ?? ''}.`;

  return (
    <Fragment>
      <Description content={title} useBold={true} isSticked={true} />
      {description && <Description content={description} trimAt={200} allowExpand={true} />}
    </Fragment>
  );
}

export default SourceHeader;
