import { PictureStructure } from '@/types/documents';

/**
 * Transform custom AW image tags into classical markdown images
 *
 * @example
 * ```
 * [image-id=123] -> ![](/path/to/image.png)
 * [image-id=123 align=centre] -> <div>![](/path/to/image.png)</div>
 * ```
 */
export function toValidImagesFormat(text: string, pictures: Array<PictureStructure>): string {
  // Handle manual and auto links the same way, do not use named groups as firefox does not support it...
  //const matches = text.matchAll(/\[image-id=(?<id>[0-9]+)(?: align=(?<align>droite|centre|gauche))?\]/g);
  const matches = text.matchAll(/\[image-id=([0-9]+)(?: align=(droite|centre|gauche))?\]/g);

  for (const match of matches) {
    //let { id, align } = match.groups;
    const id = match[1] ?? null;
    let align = match[2] ?? null;

    const picture = pictures.find((p) => p.id === Number(id));

    if (!picture) {
      continue;
    }

    const { urls } = picture;

    if (!urls) {
      text = text.replace(match[0], '');
      continue;
    }

    const { page } = urls;

    if (!page) {
      text = text.replace(match[0], '');
      continue;
    }

    const metadataAsAlt = JSON.stringify({
      id,
      width: picture.sizes.page.width,
      height: picture.sizes.page.height,
      // Prevent " from breaking JSON stringify
      description: picture.description ? picture.description.replaceAll('"', '') : null,
    });

    const image = `![${metadataAsAlt}](${page})`;

    const allowedAligns = {
      droite: 'right',
      gauche: 'left',
      centre: 'center',
    };
    if (!align) {
      align = 'centre';
    }

    let descriptionElement = '';
    if (picture.description) {
      descriptionElement = `\n\n<span class="Content__Picture__Description">${picture.description}</span>`;
    }

    let div;
    if (align === 'centre') {
      div = `\n\n<div class="Content__Picture Content__Picture--center">\n\n${image}${descriptionElement}\n\n</div>\n\n`;
    } else {
      div = `\n\n<div class="Content__Picture Content__Picture--${allowedAligns[align]}">\n\n${image}\n\n${descriptionElement}\n\n</div>\n\n`;
    }

    text = text.replace(match[0], div);
  }

  return text;
}
