import ArrowDown from '@/components/icons/ArrowDown';
import MainPicture from '@/components/ui/Block/Article/MainPicture';
import useIsUnderMediaQuery from '@/hooks/useMediaQuery';
import classNames from 'classnames';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import classes from './IdentityComposite.module.css';

type Props = {
  children: React.ReactNode;
};

function IdentityComposite(props: Props) {
  const hasPicture =
    props.children[0] &&
    props.children[0].type === Fragment &&
    props.children[0].props &&
    props.children[0].props.children &&
    props.children[0].props.children &&
    props.children[0].props.children.type === MainPicture;

  const [isExpanded, setExpanded] = useState<boolean>(false);
  const [hasToExpand, setHasToExpand] = useState<boolean>(false);
  const containerRef = useRef();

  useEffect(() => {
    const updateExpandConditions = () => {
      if (!containerRef || !containerRef.current) {
        return;
      }

      const element: any = containerRef.current;
      const containerHeight = element.clientHeight;
      const maxContainerHeight = Math.floor(window.innerHeight / 2);

      if (containerHeight <= maxContainerHeight) {
        setHasToExpand(false);
      } else {
        setHasToExpand(true);
      }
    };

    updateExpandConditions();

    window.addEventListener('resize', updateExpandConditions);
    return () => window.removeEventListener('resize', updateExpandConditions);
  }, [props.children]);

  const isMobile = useIsUnderMediaQuery(768);

  return (
    <div className={classNames(classes.compositeContainer, {})}>
      <div
        ref={containerRef}
        className={classNames(classes.container, {
          [classes.containerWithPicture]: hasPicture,
          [classes.reduced]: isMobile && hasToExpand && !isExpanded,
        })}
      >
        {props.children}
        {isMobile && hasToExpand && !isExpanded && (
          <div className={classes.showMoreLink} onClick={() => setExpanded(true)}>
            <ArrowDown />
            Afficher la suite
            <ArrowDown />
          </div>
        )}
      </div>
    </div>
  );
}

export default IdentityComposite;
