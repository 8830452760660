import ArrowDown from '@/components/icons/ArrowDown';
import CardContainer from '@/components/ui/Card/CardContainer';
import CardMultilineCounter from '@/components/ui/Card/CardMultilineCounter';
import CardMultilineLine from '@/components/ui/Card/CardMultilineLine';
import { Fragment, ReactNode, useState } from 'react';
import classes from './CardMultilineSection.module.css';

type Props = {
  title: string;
  children: Array<ReactNode>;
  maxVisibleElements?: number;
  useCounterLink?: boolean;
};

function CardMultilineSection(props: Props) {
  const { title, children, maxVisibleElements, useCounterLink } = props;
  const [isExpanded, setExpanded] = useState<boolean>(false);

  let content: ReactNode;
  if (maxVisibleElements && children.length > maxVisibleElements + 1 && !isExpanded) {
    content = (
      <Fragment>
        {children.slice(0, maxVisibleElements)}
        {useCounterLink && (
          <CardMultilineCounter count={children.length - maxVisibleElements} onClick={() => setExpanded(true)} />
        )}
        {!useCounterLink && (
          <CardMultilineLine
            icon={<ArrowDown />}
            onClick={() => setExpanded(true)}
            text={`Afficher les ${children.length - maxVisibleElements} autres éléments...`}
          />
        )}
      </Fragment>
    );
  } else {
    content = children;
  }

  return (
    <CardContainer>
      <div className={classes.title} onClick={() => setExpanded(!isExpanded)}>
        {title}
      </div>
      <ul className={classes.items}>{content}</ul>
    </CardContainer>
  );
}

export default CardMultilineSection;
