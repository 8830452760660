import { BlockRenderer } from '@/builders/types';
import References from '@/components/ui/Block/Article/References';
import { Pager, SourceListItem } from '@/types/model';

const type = 'references';

export type ReferencesBlockProps = {
  pager: Pager<SourceListItem>;
};

function render(props: ReferencesBlockProps): NonNullable<React.ReactNode> {
  return <References {...props} />;
}

export const ReferencesBlockRenderer: BlockRenderer = {
  type,
  render,
};
