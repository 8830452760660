import { UniverseByTypeListBlockProps } from '@/builders/blocks/renderers';
import GridList from '@/components/ui/List/GridList';
import TileItem from '@/components/ui/List/Item/TileItem';
import RowList from '@/components/ui/List/RowList';
import SectionTitle from '@/components/ui/Title/SectionTitle';
import useIsUnderMediaQuery from '@/hooks/useMediaQuery';
import { ListTemplate } from '@/types/model';
import { GtmAction, GtmCategory, GtmFireEvent } from '@/utils/gtm';
import classes from './EntryList.module.css';

function UniverseByTypeList(props: UniverseByTypeListBlockProps) {
  const { title, items, template } = props;

  const isMobile = useIsUnderMediaQuery(768);
  const currentTemplate = template ?? (isMobile ? ListTemplate.Row : ListTemplate.Grid);

  return (
    <section className={classes.container}>
      {title && <SectionTitle>{title}</SectionTitle>}
      {currentTemplate === ListTemplate.Row && (
        <RowList>
          {items.map((item, index) => (
            <TileItem
              key={`${item.key}-${index}`}
              title={item.name}
              href={item.url}
              subtitle={item.subtitle}
              picture={item.image}
              onClickHandler={() => {
                GtmFireEvent(
                  GtmCategory.CTAClick,
                  GtmAction.RowUniveseByTypeList,
                  title ?? null,
                  (index + 1).toString(),
                );
              }}
            />
          ))}
        </RowList>
      )}
      {currentTemplate === ListTemplate.Grid && (
        <GridList>
          {items.map((item, index) => (
            <TileItem
              key={`${item.key}-${index}`}
              title={item.name}
              href={item.url}
              subtitle={item.subtitle}
              picture={item.image}
              onClickHandler={() => {
                GtmFireEvent(
                  GtmCategory.CTAClick,
                  GtmAction.RowUniveseByTypeList,
                  title ?? null,
                  (index + 1).toString(),
                );
              }}
            />
          ))}
        </GridList>
      )}
    </section>
  );
}

export default UniverseByTypeList;
