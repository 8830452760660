import { EraHeaderBlockProps } from '@/builders/blocks/renderers';
import Description from '@/components/ui/Text/Description';
import { Fragment } from 'react';

function EraHeader(props: EraHeaderBlockProps) {
  const { era } = props;

  return (
    <Fragment>
      <Description content={era.description} />
    </Fragment>
  );
}

export default EraHeader;
