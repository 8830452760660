import { BlockRenderer } from '@/builders/types';
import Cover from '@/components/ui/Block/Cover/Cover';
import { Picture, PictureFormat } from '@/types/model';

const type = 'cover';

export type CoverBlockProps = {
  title: string;
  preTitle?: string;
  picture?: Picture;
  readTime?: number;
  useBlur?: boolean;
  pictureFormat?: PictureFormat;
  compacted?: boolean;
};

function render(props: CoverBlockProps): NonNullable<React.ReactNode> {
  return <Cover {...props} />;
}

export const CoverBlockRenderer: BlockRenderer = {
  type,
  render,
};
