import { getConfigValue } from '@/utils/config';
import axios, { AxiosRequestConfig } from 'axios';

const phpApiHost = getConfigValue('phpApiHost');

export async function phpApiVisit(type?: 'source' | 'entry', id?: number): Promise<any | any[]> {
  try {
    const config: AxiosRequestConfig = {};

    let path = '/visit';
    if (type && id) {
      path += `/${type}/${id}`;
    }

    const res = await axios.get(`${phpApiHost}${path}`, config);

    return res.data;
  } catch (error) {
    if (error) {
      console.log(error);
    }
  }

  return [];
}
