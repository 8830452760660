import Bb8Icon from '@/components/icons/Bb8Icon';
import classes from './NotYetWrittenBanner.module.css';

function NotYetWrittenBanner() {
  return (
    <div className={classes.container}>
      <div className={classes.noContent}>
        <Bb8Icon />
        <p>Cet article est référencé mais n&apos;est pas encore totalement rédigé.</p>
      </div>
      <div className={classes.aside}></div>
    </div>
  );
}

export default NotYetWrittenBanner;
