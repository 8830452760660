import { BlockRenderer } from '@/builders/types';
import Search from '@/components/ui/Block/Search/Search';
import { SearchType } from '@/types/model';
import { AggregationResult } from 'api/search';

const type = 'search';

export type SearchBlockProps = {
  searchType: SearchType;
  terms?: string;
  alwaysFullViewport?: boolean;
  onFullViewportClose?: () => void;
  filter?: AggregationResult;
  placeholder?: string;
  context?: 'menu' | 'inpage';
};

function render(props: SearchBlockProps): NonNullable<React.ReactNode> {
  return <Search {...props} />;
}

export const SearchBlockRenderer: BlockRenderer = {
  type,
  render,
};
