import FallOfRepublicEraIcon from '@/components/icons/emblems/FallOfRepublicEraIcon';
import FirstOrderEraIcon from '@/components/icons/emblems/FirstOrderEraIcon';
import GalacticEmpireEraIcon from '@/components/icons/emblems/GalacticEmpireEraIcon';
import HighRepublicEraIcon from '@/components/icons/emblems/HighRepublicEraIcon';
import LegacyEraIcon from '@/components/icons/emblems/LegacyEraIcon';
import NewJediOrderEraIcon from '@/components/icons/emblems/NewJediOrderEraIcon';
import NewRepublicEraIcon from '@/components/icons/emblems/NewRepublicEraIcon';
import PreRepublicEraIcon from '@/components/icons/emblems/PreRepublicEraIcon';
import RepublicEraIcon from '@/components/icons/emblems/RepublicEraIcon';
import { Universe } from '@/types/documents';
import { ReactNode } from 'react';
import ErasData from '../../../data/eras.json';

export type Era = {
  id: number;
  slug: string;
  name: string;
  description: string;
  universe: string;
  startAt: number;
  images: {
    page: string;
    banner: string;
  };
};

const eras = [...ErasData[Universe.Legends], ...ErasData[Universe.Canon]];

const getEraDataById = (id: number) => {
  const eraData = eras.find((eraData) => {
    return eraData.id === id;
  });

  return eraData;
};

const transformEraIdToEra = (id: number): Era => {
  const eraData = getEraDataById(id);

  return {
    id: eraData.id,
    slug: eraData.slug,
    name: eraData.name,
    startAt: parseInt(eraData.startAt?.strict || '-1000000', 10),
    description: eraData.description || '',
    universe: eraData.universe,
    images: {
      page: `/assets/eras/page_maxi_${eraData.slug}.jpg`,
      banner: '',
    },
  };
};

const eraList = {
  getEraBySlug: (slug: string): Era | null => {
    const eraData = eras.find((eraData) => {
      return eraData.slug === slug;
    });

    if (eraData) {
      return transformEraIdToEra(eraData.id);
    }

    return null;
  },
  getEraById: (id: number): Era => {
    return transformEraIdToEra(id);
  },

  getEras: (universe: string): Array<Era> => {
    const filteredEras = eras.filter((era) => {
      return era.universe === universe;
    });

    return filteredEras.map((eraData): Era => transformEraIdToEra(eraData.id));
  },

  getEraIcon: (id: number): ReactNode | null => {
    switch (id) {
      case 1:
      case 12:
        return <GalacticEmpireEraIcon />;
      case 2:
      case 15:
        return <RepublicEraIcon />;
      case 3:
      case 13:
        return <NewRepublicEraIcon />;
      case 4:
        return <LegacyEraIcon />;
      case 5:
      case 14:
        return <PreRepublicEraIcon />;
      case 7:
      case 10:
        return <FallOfRepublicEraIcon />;
      case 8:
      case 11:
        // Clone Wars
        return <FallOfRepublicEraIcon />;
      case 9:
        return <NewJediOrderEraIcon />;
      case 17:
        return <FirstOrderEraIcon />;
      case 16:
        return <HighRepublicEraIcon />;
    }

    return null;
  },
};

export default eraList;
