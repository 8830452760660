import { Pager } from '@/types/model';
import { useState } from 'react';

export function useLoadItems<T>(
  getItems: (url?: string) => Promise<Pager<T>>,
  initialPager: Pager<T>,
  onItemLoaded?: (count: number) => void,
) {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<T[]>(initialPager.results);
  const [nextPage, setNextPage] = useState<string>(initialPager.links.next);
  const [error, setError] = useState<Error>();

  async function loadMore() {
    setLoading(true);
    try {
      const { results, links } = await getItems(nextPage);
      if (!results) {
        throw new Error('No results in response');
      }

      setItems((current) => {
        if (onItemLoaded) {
          onItemLoaded(current.length);
        }
        return [...current, ...results];
      });
      setNextPage(links.next);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }

  const hasNextPage = nextPage !== undefined;

  return { loading, items, hasNextPage, error, loadMore };
}
