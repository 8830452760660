import classes from './CardMultilineCounter.module.css';

type Props = {
  count: number;
  onClick: CallableFunction;
};

function CardMultilineCounter(props: Props) {
  const { count, onClick } = props;

  return (
    <li className={classes.sourcee}>
      <a className={classes.counter} onClick={() => onClick()}>
        +{count}
      </a>
    </li>
  );
}

export default CardMultilineCounter;
