import { BlockRenderer } from '@/builders/types';
import UniverseByTypeList from '@/components/ui/Block/List/UniverseByTypeList';
import { ListTemplate, TileItem } from '@/types/model';
import { Fragment } from 'react';

const type = 'universe_by_type_list';

export type UniverseByTypeListBlockProps = {
  title?: string;
  items: Array<TileItem>;
  template?: ListTemplate;
};

function render(props: any): NonNullable<React.ReactNode> {
  if (props.items.length === 0) {
    return <Fragment />;
  }

  return <UniverseByTypeList {...props} />;
}

export const UniverseByTypeListBlockRenderer: BlockRenderer = {
  type,
  render,
};
