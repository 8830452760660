import { BlockRenderer } from '@/builders/types';
import { Fragment } from 'react';

const type = 'error';

export type ErrorBlockProps = {
  initialBlockType: string;
  error: {
    message: string;
    stack: string;
  };
};

function render(props: ErrorBlockProps): NonNullable<React.ReactNode> {
  if (process.env.NODE_ENV === 'production') {
    return <Fragment></Fragment>;
  }

  return (
    <div
      style={{
        background: '#CCC',
        padding: '1rem',
        borderBottom: '1px solid #888',
        overflow: 'auto',
      }}
    >
      <div>
        <b>Failed to render block &quot;{props.initialBlockType}&quot;.</b>
        <br />
        <small>This message is not diplayed in production conditions.</small>
      </div>
      <br />
      <code>{props.error.message}</code>
      <br />
      <br />
      <code>{props.error.stack}</code>
    </div>
  );
}

export const ErrorBlockRenderer: BlockRenderer = {
  type,
  render,
};
