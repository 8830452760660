import { BlockRenderer } from '@/builders/types';
import EntryList from '@/components/ui/Block/List/EntryList';
import { EntryListItem } from '@/types/model';
import { Fragment } from 'react';

const type = 'row_entry_list';

export type EntryListBlockProps = {
  title?: string;
  items: Array<EntryListItem>;
  limit?: {
    count: number;
    url: string;
  };
  hideUnderItemTreshold?: number;
};

function render(props: EntryListBlockProps): NonNullable<React.ReactNode> {
  if (props.hideUnderItemTreshold !== null && props.items.length <= props.hideUnderItemTreshold) {
    return <Fragment />;
  }

  return <EntryList {...props} />;
}

export const EntryListBlockRenderer: BlockRenderer = {
  type,
  render,
};
