import { GtmAction, GtmCategory, GtmFireEvent } from '@/utils/gtm';
import Link from 'next/link';
import { Fragment, ReactNode } from 'react';
import classes from './CardMultilineLine.module.css';

type Props = {
  text: string;
  icon?: ReactNode;
  href?: string | object;
  onClick?: CallableFunction;
};

function CardMultilineLine(props: Props) {
  const { icon, text, href, onClick } = props;
  const trackClick = () => GtmFireEvent(GtmCategory.CTAClick, GtmAction.ArticleCardLink, text);

  const content = (
    <Fragment>
      {icon}
      <span className={classes.name}>{text}</span>
    </Fragment>
  );

  if (href) {
    return (
      <li className={classes.source}>
        <Link href={href} className={classes.content} onClick={trackClick}>
          {content}
        </Link>
      </li>
    );
  }

  if (onClick) {
    return (
      <li className={classes.source}>
        <a
          className={classes.content}
          onClick={() => {
            trackClick();
            onClick();
          }}
        >
          {content}
        </a>
      </li>
    );
  }

  return (
    <li className={classes.source}>
      <span className={classes.content}>{content}</span>
    </li>
  );
}

export default CardMultilineLine;
