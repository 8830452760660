import { BlockRenderer } from '@/builders/types';
import Card from '@/components/ui/Block/Article/Card';
import { CardSection } from '@/types/model';

const type = 'card';

export type CardBlockProps = {
  sections: Array<CardSection>;
};

function render(props: CardBlockProps): NonNullable<React.ReactNode> {
  return <Card {...props} />;
}

export const CardBlockRenderer: BlockRenderer = {
  type,
  render,
};
