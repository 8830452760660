import React from 'react';

type Props = {
  children: React.ReactNode;
};

function ContainerComposite(props: Props) {
  return <div>{props.children}</div>;
}

export default ContainerComposite;
