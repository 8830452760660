import React from 'react';
import classes from './AsideComposite.module.css';

type Props = {
  children: React.ReactNode;
};

function AsideComposite(props: Props) {
  return (
    <section className={classes.container}>
      <div className={classes.content}>{props.children}</div>
      <aside className={classes.aside}></aside>
    </section>
  );
}

export default AsideComposite;
