import classes from './SpotlightsComposite.module.css';

type Props = {
  children: React.ReactNode;
};

function SpotlightsComposite(props: Props) {
  return <div className={classes.container}>{props.children}</div>;
}

export default SpotlightsComposite;
