import { DescriptionBlockProps } from '@/builders/blocks/Article/renderers';
import AsideComposite from '@/components/ui/Block/Article/AsideComposite';
import TextDescription from '@/components/ui/Text/Description';
import { Universe } from '@/types/documents';

function Description(props: DescriptionBlockProps) {
  const { description, type, universe } = props;

  return (
    <AsideComposite>
      <TextDescription
        type={`${type}${universe ? (universe == Universe.Canon ? ' Canon' : ' Légende') : ''}`}
        content={description}
        useBold={true}
      />
    </AsideComposite>
  );
}

export default Description;
