import CardMultilineLine from '@/components/ui/Card/CardMultilineLine';
import CardMultilineSection from '@/components/ui/Card/CardMultilineSection';
import { CardItem, CardSection as CardSectionType } from '@/types/model';
import { ReactNode } from 'react';
import classes from './CardSection.module.css';

type Props = {
  section: CardSectionType;
};

function CardSection(props: Props) {
  const { section } = props;
  const { title, items } = section;

  const createItemNodes = (items: Array<CardItem>): Array<ReactNode> => {
    return items.map((item, index) => (
      <CardMultilineSection key={index} title={item.label} maxVisibleElements={2} useCounterLink={true}>
        {item.values.map((element, index) => {
          if (element.href || element.slug) {
            return (
              <CardMultilineLine
                key={index}
                href={element.href ?? `/encyclopedie/${element.slug}`}
                text={element.text}
              />
            );
          }

          return <CardMultilineLine key={index} text={element.text} />;
        })}
      </CardMultilineSection>
    ));
  };

  const content: ReactNode = createItemNodes(items);

  return (
    <div className={classes.section}>
      {title && <div className={classes.title}>{title}</div>}
      <div className={classes.container}>{content}</div>
    </div>
  );
}

export default CardSection;
