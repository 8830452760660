import { BlockDefinition } from '@/builders/blocks/renderer/blockRenderer';
import { BlockRenderer } from '@/builders/types';

const type = 'composite';

export type CompositeBlockProps = {
  blockDefinitions: Array<BlockDefinition>;
};

function render(): NonNullable<React.ReactNode> {
  throw new Error(
    'Do not use CompositeBlockRenderer.render() directly. Use implementation like IdentityCompositeBlockRenderer instead.',
  );
}

export const CompositeBlockRenderer: BlockRenderer = {
  type,
  render,
};
