import { ContentBlockProps } from '@/builders/blocks/Article/renderers';
import AsideComposite from '@/components/ui/Block/Article/AsideComposite';
import LinkPreview from '@/components/ui/Block/Article/LinkPreview';
import MarkdownContent from '@/components/ui/Block/Article/MarkdownContent';
import Overlay from '@/components/ui/Layout/Overlay';
import useFormatedContent from '@/hooks/useFormatedContent';
import { GalleryContext } from 'context/GalleryContext';
import { Fragment, MouseEvent, useContext, useState } from 'react';
import classes from './Content.module.css';

function Content(props: ContentBlockProps) {
  const { content, pictures, defaultImageTitle } = props;
  const [linkPreview, setLinkPreview] = useState<string>();

  const galleryContext = useContext(GalleryContext);

  const pictureUrls = pictures.map((picture) => {
    return picture.urls['original'];
  });
  const formatedContent = useFormatedContent(content, pictures || []);

  // TODO change this to use another handler if desktop
  const handleLinkClick = () => {
    // setLinkPreview(href);
    // event.preventDefault();
  };

  const handleImageClick = (event: MouseEvent, id: number) => {
    const index = pictures.findIndex((picture) => picture.id === id);

    galleryContext.updateState({ ...galleryContext, isOpened: true, pictureUrls: pictureUrls, currentIndex: index });

    event.preventDefault();
  };

  return (
    <Fragment>
      {content && (
        <AsideComposite>
          <div className={classes.content}>
            <MarkdownContent
              onLinkClick={handleLinkClick}
              onImageClick={handleImageClick}
              defaultImageTitle={defaultImageTitle}
            >
              {formatedContent}
            </MarkdownContent>
          </div>
        </AsideComposite>
      )}
      {linkPreview && (
        <Overlay onClose={() => setLinkPreview(undefined)}>
          <LinkPreview href={linkPreview} />
        </Overlay>
      )}
    </Fragment>
  );
}

export default Content;
