import { BlockRenderer } from '@/builders/types';
import Content from '@/components/ui/Block/Article/Content';
import NotYetWrittenBanner from '@/components/ui/Block/Article/NotYetWrittenBanner';
import { PictureStructure } from '@/types/documents';

const type = 'content';

export type ContentBlockProps = {
  hasContent: boolean;
  forceShowContent?: boolean;
  content: string;
  pictures?: Array<PictureStructure>;
  defaultImageTitle?: string;
};

function render(props: ContentBlockProps): NonNullable<React.ReactNode> {
  if (!props.hasContent && !props.forceShowContent) {
    return <NotYetWrittenBanner />;
  }

  return <Content {...props} />;
}

export const ContentBlockRenderer: BlockRenderer = {
  type,
  render,
};
