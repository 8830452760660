import { EntryListItem, Pager } from '@/types/model';
import fetcher from '../fetch/apiFetch';

export const getEntries = async (url?: string): Promise<Pager<EntryListItem>> => {
  return fetcher(url ? url : '/content/entry');
};

export const getEntryPreview = async (url: string): Promise<EntryListItem> => {
  return fetcher(`/content/entry/preview?url=${url}`);
};
