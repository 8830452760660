/**
 * Transform manual AW links into classical markdown links
 *
 * @example
 * ```
 * [Foo]#(/foo) -> [Foo](/foo)
 * ```
 */
export function toValidLinkFormat(text: string): string {
  // Handle manual and auto links the same way
  text = text.replace(/\]#\(/g, '](');

  // Transform
  // [Grand Maitre de l'[Ordre Jedi](/ordre-jedi) Enorme](/grand-maitre-de-l-ordre-jedi-enorme)
  // In
  // [[^[+()
  // [Grand Maitre de l'Ordre Jedi Enorme](/grand-maitre-de-l-ordre-jedi-enorme)
  //text = text.replace(/\[([^\[\]]+)?\[([^\[\]]+)\]\((:?[^\(\)]*)\)/, '[$1$2');

  return text;
}
