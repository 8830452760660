import { CoverBlockProps } from '@/builders/blocks/renderers';
import TimeIcon from '@/components/icons/TimeIcon';
import Gradient from '@/components/ui/Effect/Gradient';
import useIsUnderMediaQuery from '@/hooks/useMediaQuery';
import { PictureFormat } from '@/types/model';
import { generateUrl } from '@/utils/pictureUrlGenetator';
import classNames from 'classnames';
import classes from './Cover.module.css';

function Cover(props: CoverBlockProps) {
  const { title, picture, pictureFormat, preTitle, readTime, useBlur, compacted } = props;

  const content = (
    <section className={classes.container}>
      <div className={classes.titles}>
        {preTitle && <span className={classes.preTitle}>{preTitle}</span>}
        <h1 className={classes.title}>{title}</h1>
      </div>
      {readTime && (
        <span className={classes.readTime}>
          <TimeIcon />
          {readTime} min
        </span>
      )}
    </section>
  );

  const isMobile = useIsUnderMediaQuery(768);

  return (
    <div
      className={classNames(classes.coverContainer, {
        [classes.noPicture]: !picture,
        [classes.compacted]: !isMobile && compacted,
      })}
    >
      {picture && (
        <Gradient
          backgroundImage={generateUrl(picture, pictureFormat ?? PictureFormat.Page) || ''}
          gradientClass={useBlur ? 'coverBlur' : 'cover'}
          hasImagePriority={true}
          imageTitle={title}
        >
          {content}
        </Gradient>
      )}
      {!picture && content}
    </div>
  );
}

export default Cover;
