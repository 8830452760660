function NewRepublicEraIcon() {
  return (
    <svg width="150" height="150" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M59.7346 1.92476C31.1948 8.23007 10.4869 28.2079 2.52225 56.9469C1.26119 61.3274 1.06208 63.9159 1.06208 74.6681C0.995704 85.8849 1.19482 87.9425 2.58862 93.2522C12.0798 129.425 45.5975 153.053 82.2346 149.403C108.717 146.748 130.885 131.15 142.434 107.19C160.42 69.7566 144.292 24.9557 106.527 7.2345C96.6373 2.58849 90.2656 1.19467 76.9913 0.929184C67.3674 0.730072 64.3807 0.862808 59.7346 1.92476ZM82.7656 3.38493C123.717 7.89821 152.854 45.9292 146.35 86.2168C143.894 101.283 137.389 114.093 126.571 125.243C94.3806 158.429 40.0222 153.119 14.7346 114.292C-3.9158 85.6858 0.000129819 48.3849 24.2258 24.2256C35.6417 12.7433 51.1063 5.17697 66.9028 3.31857C73.4736 2.58849 75.7302 2.58849 82.7656 3.38493Z"
        fill="black"
      />
      <path
        d="M61.1947 7.56636C61.062 9.75662 60.9293 10.0221 60.0664 9.29202C59.2036 8.56193 59.1372 8.62831 59.4691 9.62388C59.9337 10.6858 59.8009 10.6858 57.677 9.62388L55.4204 8.42919L56.947 10.1549C58.0753 11.3495 58.2744 11.8805 57.6107 12.146C57.0797 12.3451 57.2124 12.5442 58.0089 12.5442C59.0709 12.6106 59.0709 12.7433 57.677 13.938C55.9514 15.3318 56.416 15.6637 58.5399 14.5354C59.7346 13.8717 59.8009 14.0044 59.4027 15.3982C58.9381 16.9248 58.9381 16.9248 60.0001 15.5973C60.9956 14.3363 60.9956 14.4026 61.1284 16.9248C61.2611 19.3805 61.2611 19.4469 61.5266 17.3894C61.8585 15.0664 62.3231 14.8009 63.6505 16.0619C64.3806 16.792 64.3806 16.6593 63.7832 15.3982L62.9868 13.8717L65.5089 14.9336L68.031 15.9292L66.1063 14.469C64.3806 13.1416 64.3142 13.0088 65.4425 12.5442C66.7036 12.0796 66.7036 12.0133 65.4425 12.0133C64.2478 11.9469 64.2478 11.8805 65.3762 10.6195C66.7036 9.15928 66.3054 8.89379 64.3806 9.95574C63.3186 10.5531 63.1859 10.4867 63.4514 9.62388C63.7832 8.89379 63.5841 8.76105 62.854 9.02654C62.0576 9.29202 61.7257 8.82742 61.5266 7.16813C61.2611 5.04423 61.2611 5.04423 61.1947 7.56636Z"
        fill="black"
      />
      <path
        d="M88.3408 7.96459C88.208 10.2212 88.208 10.2212 87.2125 8.96017C86.1505 7.63273 86.1505 7.63273 86.6151 9.15928C87.0797 10.6858 87.0797 10.6858 84.8231 9.62388L82.6328 8.56194L84.5576 10.2212C86.2833 11.6814 86.3496 11.8141 85.2213 12.146C84.0266 12.4115 84.0266 12.4779 85.2877 12.5442C86.4824 12.6106 86.416 12.8097 84.624 14.3363L82.6328 15.9956L84.8231 14.9336C87.0797 13.8717 87.0797 13.8717 86.6151 15.3982C86.1505 16.9248 86.1505 16.9248 87.2125 15.5973C88.208 14.3363 88.208 14.3363 88.3408 16.5929C88.4735 18.7168 88.4735 18.7168 88.739 17.0575C88.9381 15.6637 89.3364 15.2655 90.0664 15.531C90.7965 15.7964 90.9293 15.6637 90.6638 14.9336C90.3319 14.0708 90.5974 14.0708 92.7877 14.9336L95.2434 15.9956L93.2523 14.3363C91.5266 12.8761 91.3939 12.677 92.5886 12.6106C93.8496 12.5442 93.8496 12.4779 92.6549 12.0133C91.5266 11.5487 91.593 11.4159 93.3187 10.0221C94.5797 9.09291 94.7125 8.82742 93.7833 9.22565C91.6594 10.2876 90.7302 10.1549 91.2611 8.76105C91.593 7.83185 91.5266 7.76548 90.8629 8.42919C89.6018 9.69026 89.0709 9.49114 88.739 7.43362C88.5399 5.8407 88.4735 5.90707 88.3408 7.96459Z"
        fill="black"
      />
      <path
        d="M112.301 17.323C112.102 19.8451 110.775 20.7743 108.452 19.9115L106.527 19.2478L108.053 20.5088C108.85 21.2389 109.514 22.1681 109.514 22.5663C109.514 22.9646 108.85 23.8938 108.053 24.6239L106.527 25.8849L108.452 25.2212C110.708 24.3584 111.969 25.1548 112.368 27.7433C112.567 29.2699 112.633 29.2035 112.766 27.2787C112.832 25.354 112.965 25.1548 113.828 25.8849C114.691 26.615 114.757 26.5486 114.359 25.5531C114.027 24.5575 114.16 24.4248 115.022 24.7566C118.009 25.9513 118.407 26.0177 117.014 24.9557C116.151 24.292 115.487 23.2301 115.487 22.5663C115.487 21.9026 116.151 20.8407 117.014 20.177C118.407 19.115 118.009 19.1814 115.155 20.3097C114.359 20.6416 114.226 20.4425 114.624 19.5132C115.022 18.3849 114.956 18.3849 114.027 19.115C113.031 19.9115 112.899 19.7124 112.633 17.7876C112.434 15.9955 112.368 15.9292 112.301 17.323Z"
        fill="black"
      />
      <path
        d="M68.6949 24.8894L62.2568 33.2522L63.9161 35.0442C65.7081 36.9027 68.0312 41.6814 67.4338 42.2788C67.3011 42.4779 65.5754 41.2168 63.717 39.4248L60.332 36.3053L57.4117 39.1593L54.425 42.0796L57.9427 45.7301C65.509 53.6947 68.5621 59.9336 68.4958 67.3673C68.4958 79.3805 61.5267 86.4159 51.7701 84.292C48.717 83.6283 47.5223 82.8982 44.7347 80.0443C39.8896 75.0664 38.1639 69.5575 38.6949 60.1991C39.2922 47.9203 43.8719 38.3628 54.1595 28.0089C60.9958 21.1062 60.7303 20.7743 51.8365 24.9557C31.1285 34.646 19.0489 54.7566 20.1108 77.5885C20.8409 92.854 26.217 104.735 37.3011 115.221C47.9205 125.31 60.0666 130.089 75.332 130.089C98.363 130.089 118.474 116.35 126.704 95.0443C129.292 88.208 130.089 83.4956 130.089 74.7345C130.089 54.6903 120.266 37.4336 103.208 27.4115C99.9559 25.4867 91.6595 21.9026 90.4648 21.9026C90.1329 21.9026 92.7878 24.823 96.3055 28.3407C103.474 35.708 106.792 40.885 109.513 49.115C112.832 59.1372 112.766 67.0354 109.248 74.6681C106.991 79.646 103.673 82.6991 99.0267 84.0266C92.3232 86.0177 87.0135 83.6283 83.894 77.2566C79.1152 67.4336 82.102 56.0177 92.1904 45.9292L95.6418 42.4779L94.3807 40.6195C93.6506 39.6239 92.4559 38.2301 91.6595 37.5664C90.0666 36.1726 90.3984 36.0398 85.0223 40.8186L82.7657 42.8097L83.2303 40.4867C83.4294 39.2257 84.5577 37.0354 85.686 35.708C86.8807 34.1814 87.4781 32.854 87.1462 32.323C86.8807 31.9248 84.0931 28.1416 80.9073 24.0265L75.0666 16.5265L68.6949 24.8894Z"
        fill="black"
      />
      <path
        d="M37.4338 18.5177C36.9692 20.0442 36.9028 20.0442 35.9736 18.8495C35.1107 17.6549 35.0444 17.6549 35.509 19.1814C35.9072 20.5088 35.7745 20.7079 34.9116 20.3761C32.0577 19.1814 31.6594 19.1814 33.186 20.3097C35.3762 21.969 35.509 23.031 33.7169 24.6239C32.1904 25.9513 32.1904 25.9513 33.6506 25.2876C35.7081 24.2256 35.9736 24.3584 35.509 26.0177C35.0444 27.4779 35.1107 27.4779 35.9736 26.2832C36.9028 25.0885 36.9692 25.0885 37.5001 26.615L38.0311 28.2079L38.2966 26.6814C38.5621 25.4203 38.7612 25.2876 39.5576 25.9513C40.3541 26.615 40.4205 26.5487 40.0222 25.5531C39.6904 24.5575 39.8231 24.4248 40.686 24.7566C43.6727 25.9513 44.0709 26.0177 42.6771 24.9557C41.8143 24.292 41.1506 23.2301 41.1506 22.5664C41.1506 21.9026 41.8143 20.8407 42.6771 20.177C44.0709 19.115 43.6727 19.1814 40.7523 20.3761C39.8895 20.7079 39.7568 20.4425 40.0886 19.1814C40.5532 17.6549 40.4869 17.6549 39.6904 18.9159C38.8939 20.177 38.8939 20.177 38.4293 18.5841L37.9647 16.9248L37.4338 18.5177Z"
        fill="black"
      />
      <path
        d="M109.911 18.7832C110.111 19.4469 110.509 19.7788 110.708 19.5796C110.907 19.3805 110.708 18.8495 110.243 18.3849C109.58 17.7876 109.513 17.854 109.911 18.7832Z"
        fill="black"
      />
      <path
        d="M109.911 26.3495C109.513 27.2787 109.58 27.3451 110.243 26.7478C110.708 26.2832 110.907 25.7522 110.708 25.5531C110.509 25.354 110.111 25.6858 109.911 26.3495Z"
        fill="black"
      />
      <path
        d="M130.885 38.2301C130.752 40.6858 129.491 41.3495 126.903 40.4867L125.111 39.823L126.571 41.084C128.23 42.4779 128.23 44.0708 126.372 45.4646C125.177 46.3938 125.244 46.3938 126.903 45.7964C129.491 44.9336 130.62 45.5309 130.952 47.9867L131.217 50.1106L131.283 47.854C131.416 45.9292 131.549 45.7301 132.412 46.4602C133.275 47.1902 133.341 47.1239 132.943 46.1283C132.611 45.1327 132.744 45 133.606 45.3318C136.46 46.5265 137.058 46.5265 135.598 45.3982C134.735 44.8009 134.071 43.8053 134.071 43.1416C134.071 42.4779 134.735 41.4823 135.598 40.8849C136.991 39.7566 136.46 39.7566 133.739 40.8849C132.943 41.2168 132.81 41.0177 133.208 40.0885C133.606 38.9602 133.54 38.9602 132.611 39.6902C131.615 40.4867 131.483 40.2876 131.217 38.3628L130.952 36.1725L130.885 38.2301Z"
        fill="black"
      />
      <path
        d="M18.5842 39.2256C18.5842 40.4203 18.4514 40.4867 17.5886 39.823C16.7258 39.0929 16.6594 39.1593 17.0576 40.1549C17.3895 41.1504 17.2567 41.2832 16.3939 40.9513C15.7966 40.6858 14.7346 40.2876 14.1373 40.0885C13.0753 39.7566 13.0753 39.823 14.1373 40.4867C14.801 40.8849 15.5975 41.8805 15.8629 42.677C16.3275 43.8053 16.0621 44.4026 14.6683 45.3982C13.0753 46.5265 13.5399 46.5265 16.3275 45.3318C17.1904 45 17.3231 45.2655 16.9913 46.5265C16.5267 48.0531 16.593 48.0531 17.3895 46.792C18.186 45.531 18.186 45.531 18.6506 47.1239L19.1152 48.7832L19.6461 47.1903C20.1107 45.6637 20.1771 45.6637 21.1063 46.8584C21.9691 48.0531 22.0355 48.0531 21.5709 46.5929C21.1063 44.9336 21.3718 44.8009 23.4293 45.8628C24.8895 46.5265 24.8895 46.5265 23.5621 45.3318C21.6373 43.5398 21.7037 42.7433 23.7612 41.0841C25.4205 39.7566 25.155 39.6903 22.1683 40.9513C21.3054 41.2832 21.1727 41.0841 21.5709 39.7566C22.0355 38.2301 22.0355 38.1637 20.9736 39.4911C20.1107 40.6195 19.978 40.6858 19.9116 39.6903C19.9116 38.031 18.5842 37.5 18.5842 39.2256Z"
        fill="black"
      />
      <path
        d="M128.496 39.3584C128.695 40.0221 129.093 40.3539 129.292 40.1548C129.492 39.9557 129.292 39.4247 128.828 38.9601C128.164 38.3628 128.098 38.4292 128.496 39.3584Z"
        fill="black"
      />
      <path
        d="M128.496 46.9248C128.098 47.854 128.164 47.9203 128.828 47.323C129.292 46.8584 129.492 46.3274 129.292 46.1283C129.093 45.9292 128.695 46.2611 128.496 46.9248Z"
        fill="black"
      />
      <path
        d="M10.7524 63.6504C10.6196 65.6416 10.4869 65.7743 9.49132 64.9779C8.49574 64.1814 8.42937 64.1814 8.8276 65.3097C9.22583 66.3053 9.09309 66.438 8.16388 66.0398C5.57539 65.0442 5.04441 65.0442 6.50459 66.1062C7.30105 66.7035 7.96477 67.6991 7.96477 68.3628C7.96477 69.0265 7.30105 70.0221 6.50459 70.6195C4.97804 71.7478 5.57539 71.7478 8.42937 70.5531C9.2922 70.2212 9.42494 70.354 9.09309 71.3495C8.69486 72.3451 8.76123 72.4115 9.55769 71.7478C10.3541 71.0841 10.5533 71.2832 10.8187 73.1416L11.0842 75.3318L11.1506 73.2743C11.2834 70.8186 12.5444 70.1549 15.1329 71.0177C16.7922 71.615 16.8586 71.615 15.6639 70.6858C13.8055 69.292 13.8055 67.6991 15.4648 66.3053L16.9249 65.0442L15.1329 65.708C12.5444 66.5708 11.4161 65.9734 11.0842 63.5177L10.8187 61.3938L10.7524 63.6504Z"
        fill="black"
      />
      <path
        d="M138.784 63.6504C138.717 65.5752 138.585 65.7743 137.722 65.0442C136.859 64.3141 136.792 64.3805 137.191 65.3761C137.523 66.3717 137.39 66.5044 136.328 66.1062C133.673 65.0442 133.142 64.9779 134.602 66.1062C135.399 66.7035 136.062 67.7655 136.062 68.4292C136.062 69.0929 135.399 70.0885 134.602 70.7522C133.142 71.8142 133.54 71.7478 136.394 70.6195C137.191 70.2876 137.323 70.4867 136.925 71.4159C136.527 72.5442 136.593 72.5442 137.589 71.7478C138.585 70.9513 138.717 71.0841 138.784 73.0752C138.916 75 138.983 75.0664 139.182 73.5398C139.58 71.0177 140.841 70.1549 143.098 71.0177C145.023 71.6814 145.023 71.615 143.562 70.6195C142.7 70.0221 142.036 69.0929 142.036 68.5619C142.036 68.031 142.7 67.0354 143.562 66.3053L145.023 65.0442L143.098 65.708C140.841 66.5708 139.58 65.7743 139.182 63.1858C138.983 61.6593 138.916 61.7257 138.784 63.6504Z"
        fill="black"
      />
      <path
        d="M12.9426 64.4469C12.6771 64.8451 12.6108 65.2434 12.8099 65.4425C12.9426 65.6416 13.2745 65.3097 13.4736 64.7124C13.9382 63.5841 13.54 63.385 12.9426 64.4469Z"
        fill="black"
      />
      <path
        d="M141.04 64.4469C140.775 64.8451 140.708 65.2434 140.907 65.4425C141.04 65.6416 141.372 65.3097 141.571 64.7124C142.036 63.5841 141.637 63.385 141.04 64.4469Z"
        fill="black"
      />
      <path
        d="M12.6106 71.615C12.6106 72.0133 12.8761 72.4779 13.2743 72.677C13.6725 72.8761 13.7389 72.6106 13.5398 72.0797C13.0752 70.885 12.6106 70.6858 12.6106 71.615Z"
        fill="black"
      />
      <path
        d="M140.708 71.615C140.708 72.0133 140.973 72.4779 141.372 72.677C141.77 72.8761 141.836 72.6106 141.637 72.0797C141.173 70.885 140.708 70.6858 140.708 71.615Z"
        fill="black"
      />
      <path
        d="M136.195 90.531C136.062 91.9248 135.797 92.323 135 91.9911C134.204 91.7257 134.005 91.8584 134.336 92.5885C134.801 93.8496 134.536 93.8496 131.881 92.5221L129.757 91.4602L131.748 93.1858C133.474 94.646 133.606 94.8451 132.412 94.9115C131.151 94.9779 131.151 95.0442 132.345 95.5089C133.474 95.9735 133.474 96.1062 131.682 97.5664C129.956 98.9602 130.155 99.0266 133.075 97.8319C133.606 97.6327 134.071 97.7655 134.071 98.1637C134.071 98.5619 134.469 98.6947 135 98.4956C135.73 98.2301 136.062 98.7611 136.261 100.354L136.527 102.544L136.593 100.354C136.726 98.2301 137.589 97.5 138.319 99.0266C138.518 99.4912 138.518 99.2257 138.319 98.3628C137.921 96.8363 137.921 96.8363 140.177 97.8982L142.367 98.9602L140.443 97.3009L138.518 95.708L140.111 95.3761L141.704 95.1106L140.111 94.9779L138.584 94.9115L140.111 93.5841L141.704 92.2566L139.779 92.9204C138.12 93.5177 137.987 93.4513 138.319 92.5221C138.651 91.5929 138.584 91.5265 137.788 92.1903C136.991 92.854 136.792 92.6549 136.527 90.7965C136.261 88.7389 136.261 88.7389 136.195 90.531Z"
        fill="black"
      />
      <path
        d="M13.2745 91.7256C13.2081 93.2522 13.2081 93.2522 12.2126 91.9248C11.1506 90.5973 11.1506 90.5973 11.6152 92.1239C12.0134 93.5841 11.9471 93.6504 10.6196 92.9203C9.82318 92.5221 9.02672 92.323 8.8276 92.4557C8.69486 92.6549 9.22583 93.1858 10.0887 93.6504L11.6152 94.5133L9.95592 94.9779L8.29663 95.4425L9.95592 95.5088L11.6152 95.6416L9.62406 97.3009C8.29663 98.4292 8.09752 98.761 9.09309 98.2964C11.1506 97.2345 12.1462 97.3672 11.6816 98.6283C11.3497 99.5575 11.4161 99.6239 12.2789 98.8938C13.0754 98.2301 13.2745 98.2301 13.2745 99.1593C13.2745 99.7566 13.6064 100.221 13.9382 100.221C14.3365 100.221 14.6019 99.5575 14.6019 98.761C14.6019 97.3672 14.6019 97.3672 15.5975 98.6283C16.4603 99.6902 16.5267 99.6902 16.1949 98.6947C15.7303 97.3672 15.863 97.3672 18.3851 98.2301L20.2435 98.8938L18.3188 97.4336C16.5931 96.1062 16.5267 95.9734 17.655 95.5088C18.8497 95.0442 18.8497 94.9779 17.5887 94.9779C16.394 94.9115 16.5267 94.7124 18.2524 93.1858L20.2435 91.5265L17.9869 92.6549C15.7303 93.7168 15.7303 93.7168 16.1285 92.1239C16.5267 90.6637 16.4603 90.6637 15.5975 91.8584C14.6019 93.1195 14.6019 93.1195 14.6019 91.7256C14.6019 90.9292 14.3365 90.2655 13.9382 90.2655C13.6064 90.2655 13.2745 90.9292 13.2745 91.7256Z"
        fill="black"
      />
      <path
        d="M27.2788 114.093C27.2124 116.018 27.0796 116.217 26.2168 115.487C25.354 114.757 25.2876 114.823 25.6195 115.752C25.9513 116.681 25.8186 116.748 24.1593 116.15L22.2345 115.487L23.8274 116.814C25.2212 118.009 25.2212 118.142 24.1593 118.208C22.9646 118.208 22.9646 118.274 24.1593 118.739C25.2876 119.204 25.2212 119.336 23.4956 120.664L21.5708 122.124L23.4292 121.46C25.9513 120.597 26.0841 120.597 25.6195 121.858C25.2876 122.788 25.354 122.854 26.2168 122.124C27.0796 121.394 27.2124 121.593 27.2788 123.186C27.3451 124.978 27.4115 124.845 28.2743 122.19C28.4735 121.46 28.7389 121.46 29.5354 122.124C30.3982 122.854 30.4646 122.788 30.0664 121.792C29.7345 120.796 29.8673 120.664 30.7301 120.996C33.7832 122.257 34.0487 122.19 32.323 120.796C30.8628 119.668 30.5973 119.137 31.3274 118.739C31.9912 118.274 31.9912 118.142 31.1947 118.142C30.531 118.142 30.7301 117.677 31.8584 116.748C33.4513 115.42 33.0531 115.42 30.1327 116.615C29.2035 116.947 29.1372 116.814 29.8673 115.42C30.531 114.093 30.531 113.96 29.8009 114.624C28.4735 115.885 28.0089 115.686 27.677 113.628C27.4115 112.035 27.4115 112.035 27.2788 114.093Z"
        fill="black"
      />
      <path
        d="M122.257 114.093C122.124 116.018 121.991 116.217 121.128 115.487C120.265 114.757 120.199 114.823 120.597 115.819C120.929 116.814 120.796 116.947 119.735 116.549C116.748 115.354 116.549 115.354 118.142 116.681C119.27 117.677 119.469 118.075 118.805 118.142C117.942 118.142 117.942 118.274 118.739 118.739C119.535 119.27 119.469 119.602 118.075 120.796C116.549 122.124 116.549 122.19 118.009 121.46C120.332 120.332 121.991 121.195 122.323 123.65L122.589 125.774L122.655 123.65C122.788 121.195 124.513 120.332 126.903 121.46C128.363 122.19 128.363 122.124 126.836 120.796C125.442 119.602 125.376 119.27 126.173 118.739C126.969 118.274 126.969 118.142 126.106 118.142C125.442 118.075 125.841 117.478 127.102 116.416L129.093 114.69L126.969 115.752C124.181 117.146 123.916 117.08 124.845 115.288C125.642 113.827 125.642 113.827 124.248 115.022C122.92 116.283 122.854 116.217 122.589 114.027L122.323 111.836L122.257 114.093Z"
        fill="black"
      />
      <path
        d="M124.115 122.057C124.115 122.456 124.381 122.92 124.779 123.119C125.177 123.319 125.244 123.053 125.044 122.522C124.58 121.327 124.115 121.128 124.115 122.057Z"
        fill="black"
      />
      <path
        d="M101.682 128.695C101.549 130.686 101.416 130.819 100.421 130.022C99.4249 129.226 99.3586 129.226 99.7568 130.221C100.221 131.615 100.221 131.615 97.7656 130.752L95.9072 130.088L97.3674 131.217C99.0931 132.611 99.0267 134.469 97.1683 135.929C95.8409 136.925 96.1727 136.925 99.2258 135.664C100.022 135.332 100.155 135.531 99.7568 136.46C99.3586 137.588 99.4249 137.588 100.354 136.858C101.35 136.062 101.482 136.261 101.748 138.186L102.013 140.376L102.08 138.119C102.213 136.128 102.345 135.996 103.341 136.792C104.336 137.588 104.403 137.588 104.005 136.46C103.606 135.531 103.739 135.332 104.536 135.664C107.456 136.858 107.921 136.858 106.593 135.796C104.735 134.336 104.735 132.743 106.394 131.283L107.854 130.022L106.328 130.752C104.005 131.881 102.345 131.018 102.013 128.562L101.748 126.438L101.682 128.695Z"
        fill="black"
      />
      <path
        d="M47.854 129.027C47.7876 130.686 47.6548 130.819 46.6593 130.022C45.6637 129.226 45.5973 129.226 45.9956 130.221C46.5265 131.615 46.4602 131.615 44.0044 130.752L42.146 130.022L44.1371 131.681C45.2655 132.611 45.7301 133.341 45.2655 133.407C44.8009 133.407 44.5354 133.673 44.8009 134.004C45 134.336 44.469 135.133 43.6725 135.796C42.2124 136.858 42.6106 136.792 45.531 135.597C46.3938 135.265 46.5265 135.465 46.1283 136.792C45.6637 138.319 45.6637 138.385 46.7256 137.058C47.7212 135.796 47.7212 135.796 47.7876 137.721L47.9203 139.712L48.5841 137.854C49.1814 136.128 49.3141 136.062 50.177 137.19C50.9734 138.319 51.0398 138.252 50.6416 136.792C50.3097 135.398 50.3761 135.332 51.7035 136.062C52.5664 136.46 53.3628 136.659 53.5619 136.527C53.6947 136.327 53.2301 135.796 52.4336 135.398C51.2389 134.734 51.1062 134.469 51.8363 134.004C52.5 133.606 52.5664 133.407 51.969 133.407C50.5752 133.407 50.9734 132.478 52.9646 131.15C54.6239 130.088 54.0929 130.088 51.3053 131.217C50.4425 131.549 50.3097 131.35 50.6416 130.022C51.0398 128.496 50.9734 128.496 50.1106 129.69C49.3805 130.686 49.115 130.752 48.8495 130.022C48.7168 129.491 48.3849 128.628 48.2522 128.097C48.0531 127.566 47.854 127.965 47.854 129.027Z"
        fill="black"
      />
      <path
        d="M103.872 129.491C103.606 129.889 103.54 130.288 103.739 130.487C103.872 130.686 104.204 130.354 104.403 129.757C104.867 128.628 104.469 128.429 103.872 129.491Z"
        fill="black"
      />
      <path
        d="M74.801 134.934C74.3364 136.527 74.1373 136.726 73.2744 135.996C72.478 135.332 72.4116 135.398 72.8098 136.394C73.1417 137.389 73.0089 137.522 71.947 137.124C68.9603 135.929 68.7612 135.929 70.6196 137.389C72.4116 138.85 72.4116 138.982 71.2833 139.447C70.0886 139.911 70.0886 139.978 71.3496 140.044C72.6107 140.044 72.5443 140.177 71.0178 141.438C69.4249 142.765 69.8231 142.765 72.6771 141.637C73.3408 141.372 74.0045 141.903 74.6682 143.429L75.5974 145.686L75.6638 143.695C75.7302 141.77 75.7302 141.77 76.7258 143.031C77.7877 144.358 77.7877 144.358 77.3231 142.832C76.9249 141.438 76.9912 141.438 78.7833 142.035L80.6417 142.699L79.0488 141.372C77.655 140.177 77.655 140.044 78.7169 139.978C79.9116 139.978 79.9116 139.911 78.7169 139.447C77.5886 138.982 77.655 138.85 79.3806 137.522L81.3054 136.062L79.447 136.726C76.9249 137.588 76.7921 137.588 77.2567 136.327C77.5886 135.398 77.5222 135.332 76.7258 135.996C75.9293 136.659 75.7302 136.46 75.5311 134.934L75.3319 133.075L74.801 134.934Z"
        fill="black"
      />
      <path
        d="M72.7435 143.164C72.3453 144.093 72.4116 144.159 73.0753 143.562C73.5399 143.097 73.7391 142.566 73.5399 142.367C73.3408 142.168 72.9426 142.5 72.7435 143.164Z"
        fill="black"
      />
    </svg>
  );
}

export default NewRepublicEraIcon;
