import Gradient from '@/components/ui/Effect/Gradient';
import { Picture } from '@/types/model';
import { generateUrl } from '@/utils/pictureUrlGenetator';
import Link from 'next/link';
import classes from './TileItem.module.css';

type Props = {
  title: string;
  href: string;
  subtitle: {
    elementCount: number;
    elementLabel: string;
  };
  picture: Picture;
  onClickHandler?: () => void;
};

function TileItem(props: Props) {
  const { title, href, subtitle, picture, onClickHandler } = props;

  return (
    <Gradient backgroundImage={generateUrl(picture, 'tile')} gradientClass={'tile'} imageTitle={title}>
      <Link href={href} onClick={onClickHandler ? () => onClickHandler() : null}>
        <div className={classes.container}>
          <div className={classes.title}>{title}</div>
          <span className={classes.subtitle}>
            <span className={classes.count}>{subtitle.elementCount} </span>
            {subtitle.elementLabel}
          </span>
        </div>
      </Link>
    </Gradient>
  );
}

export default TileItem;
