// Add a polyfill for older browsers
if (!String.prototype.replaceAll) {
  String.prototype.replaceAll = function (str, newStr) {
    // If a regex pattern
    if (Object.prototype.toString.call(str).toLowerCase() === '[object regexp]') {
      return this.replace(str, newStr);
    }

    // If a string
    return this.replace(new RegExp(str, 'g'), newStr);
  };
}

/**
 * Transform blockquotes to valid markdown
 */
export function toValidBlockquoteFormat(text: string): string {
  // Add extra quote new lines "\n>" to ensure markdown return to line
  const returnLineMatches = text.matchAll(/>(?<quote>.+)/g);
  for (const match of returnLineMatches) {
    const { quote } = match.groups;
    text = text.replace(quote, `${quote}\n>`);
  }

  // Identify "__author" and transform it into a <cite> tag
  //text = text.replaceAll(/>?__(.+)/g, `<cite>$1</cite>`);

  return text;
}
