import { BlockRenderer } from '@/builders/types';
import Description from '@/components/ui/Block/Article/Description';
import { Universe } from '@/types/documents';

const type = 'description';

export type DescriptionBlockProps = {
  description: string;
  universe?: Universe;
  type?: string;
};

function render(props: DescriptionBlockProps): NonNullable<React.ReactNode> {
  return <Description {...props} />;
}

export const DescriptionBlockRenderer: BlockRenderer = {
  type,
  render,
};
