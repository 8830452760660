import { BlockRenderer } from '@/builders/types';
import Highlights from '@/components/ui/Block/Highlight/Highlights';
import { HighlightItem } from '@/types/model';

const type = 'highlight';

export type HighlightBlockProps = {
  highlights: Array<HighlightItem>;
};

function render(props: HighlightBlockProps): NonNullable<React.ReactNode> {
  return <Highlights {...props} />;
}

export const HighlightBlockRenderer: BlockRenderer = {
  type,
  render,
};
