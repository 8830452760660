import { EraEntryListBlockProps } from '@/builders/blocks/renderers';
import ChronologyContainer from '@/components/ui/Chronology/ChronologyContainer';

function EraEntryList(props: EraEntryListBlockProps) {
  return (
    <section>
      <ChronologyContainer items={props.items} showFirstDate={true} />
    </section>
  );
}

export default EraEntryList;
