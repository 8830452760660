import { BlockRenderer } from '@/builders/types';
import SingleEntrySpotlight from '@/components/ui/Block/Spotlight/SingleEntrySpotlight';
import { EntryListItem } from '@/types/model';

const type = 'single_entry_spotlight';

export type SingleEntrySpotlightBlockProps = {
  title?: string;
  entry: EntryListItem;
};

function render(props: SingleEntrySpotlightBlockProps): NonNullable<React.ReactNode> {
  return <SingleEntrySpotlight {...props} />;
}

export const SingleEntrySpotlightBlockRenderer: BlockRenderer = {
  type,
  render,
};
