import ArrowForwardIcon from '@/components/icons/ArrowForwardIcon';
import LoaderIcon from '@/components/icons/LoaderIcon';
import NewTabIcon from '@/components/icons/NewTabIcon';
import { EntryListItem } from '@/types/model';
import { generateUrl } from '@/utils/pictureUrlGenetator';
import { getEntryPreview } from 'api/content/entry';
import Image from 'next/legacy/image';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import classes from './LinkPreview.module.css';

type Props = {
  href: string;
};

function LinkPreview(props: Props) {
  const { href } = props;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [entry, setEntry] = useState<EntryListItem>();

  useEffect(() => {
    getEntryPreview(href).then((result) => {
      setEntry(result);
      setIsLoading(false);
    });
  }, [href]);

  return (
    <div className={classes.container}>
      {isLoading && (
        <div className={classes.loader}>
          <LoaderIcon />
        </div>
      )}
      {entry && (
        <div className={classes.preview}>
          <div className={classes.head}>
            {entry.image && (
              <Image className={classes.picture} src={generateUrl(entry.image, 'square')} alt={entry.name} />
            )}
            <div className={classes.titles}>
              <h1 className={classes.title}>{entry.name}</h1>
              <div className={classes.type}>{entry.type.name}</div>
            </div>
          </div>
          <p className={classes.description}>{entry.description}</p>
          <div className={classes.links}>
            <Link href={`/encyclopedie/${entry.slug}`} target="_blank">
              <NewTabIcon /> Nouvel onglet
            </Link>
            <Link href={`/encyclopedie/${entry.slug}`}>
              <ArrowForwardIcon /> Lire l&apos;article
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}

export default LinkPreview;
