import { BlockRenderer } from '@/builders/types';
import HeaderSpace from '@/components/ui/Layout/Header/HeaderSpace';

const type = 'header-space';

function render(): NonNullable<React.ReactNode> {
  return <HeaderSpace />;
}

export const HeaderSpaceBlockRenderer: BlockRenderer = {
  type,
  render,
};
