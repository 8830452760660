import { getBlockComponent } from '@/builders/blocks/renderer/blockRenderer';
import { CompositeBlockProps } from '@/builders/blocks/renderers';
import { BlockRenderer } from '@/builders/types';
import IdentityComposite from '@/components/ui/Block/Article/IdentityComposite';
import { Fragment } from 'react';

const type = 'identity-composite';

function render(props: CompositeBlockProps): NonNullable<React.ReactNode> {
  return (
    <IdentityComposite>
      {props.blockDefinitions.map((blockDefinition, index) => {
        return <Fragment key={index}>{getBlockComponent(blockDefinition)}</Fragment>;
      })}
    </IdentityComposite>
  );
}

export const IdentityCompositeBlockRenderer: BlockRenderer = {
  type,
  render,
};
