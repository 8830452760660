import { SourceListBlockProps } from '@/builders/blocks/renderers';
import GridList from '@/components/ui/List/GridList';
import CardItem from '@/components/ui/List/Item/CardItem';
import CardSeeMoreItem from '@/components/ui/List/Item/CardSeeMoreItem';
import RowList from '@/components/ui/List/RowList';
import SectionTitle from '@/components/ui/Title/SectionTitle';
import useIsUnderMediaQuery from '@/hooks/useMediaQuery';
import { PictureFormat, PictureRatio } from '@/types/model';
import { getPathToSource } from '@/utils/routes';
import classes from './EntryList.module.css';

function SourceList(props: SourceListBlockProps) {
  const { title, items, limit } = props;

  const isMobile = useIsUnderMediaQuery(768);
  const template = isMobile ? 'row' : 'grid';

  // Do not add a plus link for less than 5 elements
  const useLimit = limit && items.length > limit.count + 5;
  const moreNumber = useLimit ? items.length - limit.count : null;
  const limitedItems = useLimit ? items.slice(0, limit.count) : items;

  // Elements array must be created before to prevent the slider
  // to have an empty node due to the see more condition
  const elements = limitedItems.map((item) => (
    <CardItem
      key={`${item.slug}`}
      title={item.name}
      href={getPathToSource(item)}
      subtitle={item.type.name}
      picture={item.image}
      pictureRatio={PictureRatio.Ratio_2_3}
      pictureFormat={PictureFormat.Page}
      allowPictureScale={false}
    />
  ));

  if (moreNumber) {
    elements.push(
      <CardSeeMoreItem
        key={'seemore'}
        title={`Voir les ${moreNumber}`}
        subtitle={`autres articles`}
        href={limit.url}
        allowPictureScale={false}
      />,
    );
  }
  return (
    <section className={classes.container}>
      {title && <SectionTitle>{title}</SectionTitle>}
      {template === 'row' && <RowList>{elements}</RowList>}
      {template === 'grid' && <GridList>{elements}</GridList>}
    </section>
  );
}

export default SourceList;
