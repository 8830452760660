import { MainPictureBlockProps } from '@/builders/blocks/renderers';
import { PictureFormat } from '@/types/model';
import { generateUrl } from '@/utils/pictureUrlGenetator';
import { GalleryContext } from 'context/GalleryContext';
import Image from 'next/legacy/image';
import { useContext } from 'react';

function MainPicture(props: MainPictureBlockProps) {
  const { picture, pictureFormat, title } = props;

  const galleryContext = useContext(GalleryContext);

  const handleImageClick = () => {
    galleryContext.updateState({ ...galleryContext, isOpened: true, pictureUrls: [picture.urls['original']] });
  };

  const format = pictureFormat ?? PictureFormat.Page;
  return (
    <div className="main-picture">
      <a href="#" onClick={() => handleImageClick()}>
        <Image
          src={generateUrl(picture, format)}
          alt={title}
          width={picture.sizes[format].width}
          height={picture.sizes[format].height}
          layout="responsive"
          priority={true}
        />
      </a>
    </div>
  );
}

export default MainPicture;
