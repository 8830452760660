import { AffiliationsBlockProps } from '@/builders/blocks/Article/renderers';
import AffiliationIcon from '@/components/icons/AffiliationIcon';
import CardMultilineLine from '@/components/ui/Card/CardMultilineLine';
import CardMultilineSection from '@/components/ui/Card/CardMultilineSection';

function Affiliations(props: AffiliationsBlockProps) {
  return (
    <CardMultilineSection title="Affiliations" maxVisibleElements={4}>
      {props.entries.map((entry) => (
        <CardMultilineLine
          key={entry.slug}
          href={`/encyclopedie/${entry.slug}`}
          text={entry.name}
          icon={<AffiliationIcon />}
        />
      ))}
    </CardMultilineSection>
  );
}

export default Affiliations;
