import { UniverseEraListBlockProps } from '@/builders/blocks/renderers';
import ChronologyContainer from '@/components/ui/Chronology/ChronologyContainer';

function UniverseEraList(props: UniverseEraListBlockProps) {
  return (
    <section>
      <ChronologyContainer items={props.items} showFirstDate={false} />
    </section>
  );
}

export default UniverseEraList;
