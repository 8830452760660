import { IdentityCompositeBlockRenderer } from '@/builders/blocks/Article/IdentityCompositeBlockRenderer';
import { ContainerCompositeBlockRenderer } from '@/builders/blocks/ContainerCompositeBlockRenderer';
import { getBlockComponent, registerCompositeBlockRenderer } from '@/builders/blocks/renderer/blockRenderer';
import { SpotlightsCompositeBlockRenderer } from '@/builders/blocks/SpotlightsCompositeBlockRenderer';
import { PageResponse } from '@/builders/pageResolver';
import { phpApiVisit } from 'api/php/visit';
import Head from 'next/head';
import { Fragment, useEffect } from 'react';
import { jsonLdScriptProps } from 'react-schemaorg';

type Props = {
  page: PageResponse;
};

function Page(props: Props) {
  registerCompositeBlockRenderer(IdentityCompositeBlockRenderer);
  registerCompositeBlockRenderer(SpotlightsCompositeBlockRenderer);
  registerCompositeBlockRenderer(ContainerCompositeBlockRenderer);

  useEffect(() => {
    const statsTrigger = props.page.statsTrigger;
    if (window) {
      let statFunction;
      if (statsTrigger.type && statsTrigger.id) {
        statFunction = () => phpApiVisit(statsTrigger.type, statsTrigger.id);
      } else {
        statFunction = () => phpApiVisit();
      }

      window.setTimeout(statFunction, 3000);
    }
  }, [props.page.statsTrigger]);

  return (
    <Fragment>
      <Head>
        {props.page.title && <title>{`${props.page.title} - Anakinworld`}</title>}
        {props.page.description && <meta name="description" content={props.page.description} />}
        {props.page.structuredData.canonicalUrl && (
          <link rel="canonical" href={props.page.structuredData.canonicalUrl} />
        )}
        {props.page.structuredData.jsonLd &&
          props.page.structuredData.jsonLd.map((jsonLd, i) => <script key={i} {...jsonLdScriptProps(jsonLd)} />)}
      </Head>
      {props.page.blocks.map((block, index) => (
        <Fragment key={index}>{getBlockComponent(block)}</Fragment>
      ))}
    </Fragment>
  );
}

export default Page;
