import { BlockRenderer } from '@/builders/types';
import GridSourceList from '@/components/ui/Block/List/GridSourceList';
import { Pager, PictureRatio, SourceListItem } from '@/types/model';

const type = 'grid_source_list';

export type GridSourceListBlockProps = {
  title?: string;
  pager: Pager<SourceListItem>;
  pictureRatio?: PictureRatio;
};

function render(props: GridSourceListBlockProps): NonNullable<React.ReactNode> {
  return <GridSourceList {...props} />;
}

export const GridSourceListBlockRenderer: BlockRenderer = {
  type,
  render,
};
