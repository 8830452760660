import { BlockRenderer } from '@/builders/types';
import SourceHeader from '@/components/ui/Block/Source/SourceHeader';
import { SourceType } from '@/utils/references/sourceTypes';

const type = 'source-header';

export type SourceHeaderBlockProps = {
  type: SourceType;
  description: string;
  firstReleasedAt?: string;
  period?: string;
};

function render(props: SourceHeaderBlockProps): NonNullable<React.ReactNode> {
  return <SourceHeader {...props} />;
}

export const SourceHeaderBlockRenderer: BlockRenderer = {
  type,
  render,
};
