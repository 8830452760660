import { BlockRenderer } from '@/builders/types';
import VersionSwitch from '@/components/ui/Block/Navigation/VersionSwitch';
import { Universe } from '@/types/documents';
import { TabTemplate } from '@/types/model';

const type = 'global-version-switch';

export type VersionSwitchBlockProps = {
  toUniverse: Universe;
  urls: {
    [key: string]: string;
  };
  template?: TabTemplate;
};

function render(props: VersionSwitchBlockProps): NonNullable<React.ReactNode> {
  return <VersionSwitch {...props} />;
}

export const VersionSwitchBlockRenderer: BlockRenderer = {
  type,
  render,
};
