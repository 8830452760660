import { BlockRenderer } from '@/builders/types';
import UniverseEraList from '@/components/ui/Block/List/UniverseEraList';
import { DatedItem } from '@/types/model';

const type = 'chronology';

export interface UniverseEraListBlockProps {
  items: Array<DatedItem>;
}

function render(props: UniverseEraListBlockProps): NonNullable<React.ReactNode> {
  return <UniverseEraList {...props} />;
}

export const UniverseEraListBlockRenderer: BlockRenderer = {
  type,
  render,
};
