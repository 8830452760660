function PreRepublicEraIcon() {
  return (
    <svg width="150" height="150" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M62.0769 0C60.6923 26.2136 53.0769 41.5049 45.4615 51.699V53.1553H60C64.8462 46.6019 69 39.3204 69 39.3204V37.1359C64.1538 28.3981 65.5385 6.5534 69.6923 0H62.0769ZM77.3077 0C81.4615 6.5534 82.8462 28.3981 78 37.1359V39.3204C78 39.3204 82.1538 46.6019 87 53.1553H101.538V51.699C93.9231 41.5049 86.3077 26.2136 84.9231 0H77.3077ZM73.5 39.3204C67.1945 39.3204 62.0769 53.6738 62.0769 71.3592C62.0769 78.1922 67.1945 83.7379 73.5 83.7379C79.8055 83.7379 84.9231 78.1922 84.9231 71.3592C84.9231 53.6738 79.8055 39.3204 73.5 39.3204ZM55.8462 55.3398C53.5532 55.3398 51.6923 57.2971 51.6923 59.7087C51.6923 62.1204 53.5532 64.0777 55.8462 64.0777H60.6923V55.3398H55.8462ZM86.3077 55.3398V64.0777H91.1539C93.4468 64.0777 95.3077 62.1204 95.3077 59.7087C95.3077 57.2971 93.4468 55.3398 91.1539 55.3398H86.3077ZM42 66.2621C51 86.6505 60.6923 118.689 66.2308 150H71.0769C66.9231 141.99 64.1538 117.233 71.0769 104.854V102.67C71.0769 102.67 64.1538 80.8252 57.2308 66.2621H42ZM89.7692 66.2621C82.8462 80.8252 75.9231 102.67 75.9231 102.67V104.854C82.8462 117.233 80.0769 141.99 75.9231 150H80.7692C86.3077 118.689 96 86.6505 105 66.2621H89.7692Z"
        fill="black"
      />
    </svg>
  );
}

export default PreRepublicEraIcon;
