import { TabTemplate } from '@/types/model';
import classNames from 'classnames';
import classes from './TabContainer.module.css';

interface Props {
  children: React.ReactNode;
  template?: TabTemplate;
}

function TabContainer(props: Props) {
  return (
    <div
      className={classNames(classes.container, {
        [classes.left]: props.template && props.template === TabTemplate.Left,
      })}
    >
      {props.children}
    </div>
  );
}

export default TabContainer;
