import { HeaderSpaceBlockRenderer } from '@/builders/blocks/HeaderSpaceBlockRenderer';
import {
  AffiliationsBlockRenderer,
  ArticleVersionSwitchBlockRenderer,
  BackButtonBlockRenderer,
  BreadcrumbBlockRenderer,
  CardBlockRenderer,
  ContentBlockRenderer,
  CoverBlockRenderer,
  DescriptionBlockRenderer,
  EntryListBlockRenderer,
  EraEntryListBlockRenderer,
  EraHeaderBlockRenderer,
  ErrorBlockRenderer,
  GridEntryListBlockRenderer,
  GridSourceListBlockRenderer,
  HighlightBlockRenderer,
  MainPictureBlockRenderer,
  MetadataBlockRenderer,
  ReferencesBlockRenderer,
  SearchBlockRenderer,
  SingleEntrySpotlightBlockRenderer,
  SourceHeaderBlockRenderer,
  SourceListBlockRenderer,
  UniverseByTagListBlockRenderer,
  UniverseByTypeListBlockRenderer,
  UniverseEraListBlockRenderer,
  VersionSwitchBlockRenderer,
} from '@/builders/blocks/renderers';
import { SpotlightBlockRenderer } from '@/builders/blocks/SpotlightBlockRenderer';
import { UniverseBySourceTypeListBlockRenderer } from '@/builders/blocks/UniverseBySourceTypeListBlockRenderer';
import { Block, BlockRenderer } from '@/builders/types';
import { Fragment } from 'react';

const blocks = [
  MetadataBlockRenderer,
  DescriptionBlockRenderer,
  AffiliationsBlockRenderer,
  ReferencesBlockRenderer,
  ArticleVersionSwitchBlockRenderer,
  CardBlockRenderer,
  ContentBlockRenderer,

  EraHeaderBlockRenderer,
  SourceHeaderBlockRenderer,
  VersionSwitchBlockRenderer,
  UniverseEraListBlockRenderer,
  SingleEntrySpotlightBlockRenderer,
  SearchBlockRenderer,
  UniverseByTypeListBlockRenderer,
  UniverseByTagListBlockRenderer,
  UniverseBySourceTypeListBlockRenderer,
  SourceListBlockRenderer,
  EntryListBlockRenderer,
  GridSourceListBlockRenderer,
  GridEntryListBlockRenderer,
  ErrorBlockRenderer,
  EraEntryListBlockRenderer,
  CoverBlockRenderer,
  BackButtonBlockRenderer,
  HighlightBlockRenderer,
  SpotlightBlockRenderer,
  MainPictureBlockRenderer,
  HeaderSpaceBlockRenderer,
  BreadcrumbBlockRenderer,
];

export type BlockDefinition = {
  type: string;
  props: any;
};

export function getBlockDefinition(block: Block<any>): BlockDefinition {
  return {
    type: block.type,
    props: block.getProps(),
  };
}

export function getBlockComponent(blockDefintion: BlockDefinition): NonNullable<React.ReactNode> {
  const blockComponent = blocks.find((block) => {
    return block && block.type === blockDefintion.type;
  });

  if (!blockComponent) {
    console.warn(`Block ${blockDefintion.type} tried to be rendered, but was not declared.`, new Error());
    return <Fragment />;
  }

  return blockComponent.render(blockDefintion.props);
}

/**
 * Composite block renderers must be defined at Page render time to prevent circular dependencies.
 *
 * @see Page component
 */
export function registerCompositeBlockRenderer(block: BlockRenderer) {
  if (!blocks.includes(block)) {
    blocks.push(block);
  }
}
