import GridList from '@/components/ui/List/GridList';
import TileItem from '@/components/ui/List/Item/TileItem';
import RowList from '@/components/ui/List/RowList';
import SectionTitle from '@/components/ui/Title/SectionTitle';
import useIsUnderMediaQuery from '@/hooks/useMediaQuery';
import { ListTemplate, TileItem as TileItemType } from '@/types/model';
import classes from './EntryList.module.css';

type Props = {
  title?: string;
  items: Array<TileItemType>;
  template?: ListTemplate;
};

function UniverseBySourceTypeList(props: Props) {
  const { title, items, template } = props;

  const isMobile = useIsUnderMediaQuery(768);
  const currentTemplate = template ?? (isMobile ? ListTemplate.Row : ListTemplate.Grid);

  return (
    <section className={classes.container}>
      {title && <SectionTitle>{title}</SectionTitle>}
      {currentTemplate === ListTemplate.Row && (
        <RowList>
          {items.map((item) => (
            <TileItem key={item.key} title={item.name} href={item.url} subtitle={item.subtitle} picture={item.image} />
          ))}
        </RowList>
      )}
      {currentTemplate === ListTemplate.Grid && (
        <GridList>
          {items.map((item) => (
            <TileItem key={item.key} title={item.name} href={item.url} subtitle={item.subtitle} picture={item.image} />
          ))}
        </GridList>
      )}
    </section>
  );
}

export default UniverseBySourceTypeList;
