import SectionTitle from '@/components/ui/Title/SectionTitle';
import { Pager, SourceListItem } from '@/types/model';
import { sourceTypes } from '@/utils/references/sourceTypes';
import { getPathToSource, sourceHasPath } from '@/utils/routes';
import Link from 'next/link';
import classes from './References.module.css';

type Props = {
  pager: Pager<SourceListItem>;
};

function References(props: Props) {
  const items = props.pager.results;

  const itemsByType = {};
  items.forEach((item) => {
    const type = sourceTypes.get(item.type.slug);
    if (!itemsByType[type.name]) {
      itemsByType[type.name] = [];
    }

    itemsByType[type.name].push(item);
  });

  const renderType = (type, items) => {
    return items.map((item) => (
      <li className={classes.reference} key={`${item.slug}`}>
        {sourceHasPath(item) && (
          <span>
            <Link href={getPathToSource(item)}>{item.name}</Link>, {new Date(item.firstReleasedAt).getFullYear()}
          </span>
        )}
        {!sourceHasPath(item) && (
          <span>
            {item.name}, {new Date(item.firstReleasedAt).getFullYear()}
          </span>
        )}
      </li>
    ));
  };

  return (
    <section className={classes.container}>
      <SectionTitle>Références</SectionTitle>
      <div className={classes.referenceTypes}>
        {Object.entries(itemsByType).map(([type, items]) => (
          <div key={`reference-type-${type}`} className={classes.referenceType}>
            <b key={type}>{type}</b>
            <ul className={classes.references}>{renderType(type, items)}</ul>
          </div>
        ))}
      </div>
    </section>
  );
}

export default References;
