import { BreadcrumbBlockProps } from '@/builders/blocks/renderers';
import useIsUnderMediaQuery from '@/hooks/useMediaQuery';
import Link from 'next/link';
import { Fragment } from 'react';
import classes from './Breadcrumb.module.css';

function Breadcrumb(props: BreadcrumbBlockProps) {
  const { breadcrumb } = props;

  const isMobile = useIsUnderMediaQuery(768);
  if (isMobile) {
    return <Fragment />;
  }

  return (
    <ul className={classes.container}>
      {breadcrumb.map((item, index) => {
        if (!item.url) {
          return <li key={index}>{item.title}</li>;
        }

        return (
          <li key={index}>
            <Link href={item.url}>{item.title}</Link>
          </li>
        );
      })}
    </ul>
  );
}

export default Breadcrumb;
