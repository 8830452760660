import ChronologyDateItem from '@/components/ui/Chronology/ChronologyDateItem';
import ChronologyItem from '@/components/ui/Chronology/ChronologyItem';
import { DatedItem } from '@/types/model';
import { toStarWarsDate } from '@/utils/helpers';
import { Fragment } from 'react';
import classes from './ChronologyContainer.module.css';

interface Props {
  items: Array<DatedItem>;
  showFirstDate: boolean;
}

function ChronologyContainer(props: Props) {
  const { items, showFirstDate } = props;
  let currentDate = null;

  return (
    <ul className={classes.container}>
      <li className={classes.line}></li>
      {items.map((item, index) => {
        const displayDate = (showFirstDate || currentDate !== null) && currentDate !== item.date;
        currentDate = item.date;

        return (
          <Fragment key={index}>
            {displayDate && currentDate && <ChronologyDateItem date={toStarWarsDate(currentDate.toString())} />}
            <ChronologyItem item={item} />
          </Fragment>
        );
      })}
    </ul>
  );
}

export default ChronologyContainer;
