/**
 * Remove lasting artifacts from markdown text
 * This is a failsafe on front side, but it should be cleaned on back side
 */
export function toCleanedTextFormat(text: string): string {
  // Remove { and } no link tags
  text = text.replace(/({|})/g, '');

  return text;
}
