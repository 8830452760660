import ArrowForwardIcon from '@/components/icons/ArrowForwardIcon';
import Link from 'next/link';
import classes from './CardSeeMoreItem.module.css';

type Props = {
  title: string;
  subtitle?: string;
  href: string;
  useAlternateStyle?: boolean;
  allowPictureScale?: boolean;
};

function CardSeeMoreItem(props: Props) {
  const { title, href } = props;

  return (
    <div className={classes.container}>
      <Link href={href} className={classes.link}>
        <ArrowForwardIcon />
        {title}
      </Link>
    </div>
  );
}

export default CardSeeMoreItem;
