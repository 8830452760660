import classNames from 'classnames';
import Link from 'next/link';
import classes from './TabItem.module.css';

interface Props {
  title: string;
  href: string;
  isActive: boolean;
}

function TabItem(props: Props) {
  const { title, isActive, href } = props;
  return (
    <div className={classNames(classes.item, { [classes.isActive]: isActive })}>
      <Link href={href}>{title}</Link>
    </div>
  );
}

export default TabItem;
