import { BlockRenderer } from '@/builders/types';
import Metadata from '@/components/ui/Block/Article/Metadata';

const type = 'metadata';

export type MetadataBlockProps = {
  hasContent: boolean;
  author: string;
  lastUpdatedAt: string;
};

function render(props: MetadataBlockProps): NonNullable<React.ReactNode> {
  if (!props.hasContent) {
    return;
  }

  return <Metadata {...props} />;
}

export const MetadataBlockRenderer: BlockRenderer = {
  type,
  render,
};
