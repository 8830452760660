import { SpotlightBlockProps } from '@/builders/blocks/SpotlightBlockRenderer';
import Button from '@/components/ui/Button/Button';
import Gradient from '@/components/ui/Effect/Gradient';
import CardItem from '@/components/ui/List/Item/CardItem';
import RowList from '@/components/ui/List/RowList';
import { PictureFormat } from '@/types/model';
import { GtmAction, GtmCategory, GtmFireEvent } from '@/utils/gtm';
import { generateUrl } from '@/utils/pictureUrlGenetator';
import { resolveUrl } from '@/utils/routes';
import Image from 'next/legacy/image';
import Link from 'next/link';
import classes from './Spotlight.module.css';

function Spotlight(props: SpotlightBlockProps) {
  const { title, items, logo, background, target, label } = props;
  return (
    <Gradient
      backgroundImage={background ? generateUrl(background, PictureFormat.Page) : ''}
      gradientClass={'spotlight'}
      imageTitle={label ?? title}
    >
      <section className={classes.container}>
        <h1 className={classes.title}>{title}</h1>
        {logo && (
          <div className={classes.logo}>
            <Link
              href={resolveUrl(target)}
              onClick={() => GtmFireEvent(GtmCategory.CTAClick, GtmAction.UniverseSpotlight, label)}
            >
              <Image
                src={generateUrl(logo, PictureFormat.Page)}
                alt={label ? `Logo de ${label}` : title}
                width={logo.sizes[PictureFormat.Page].width}
                height={logo.sizes[PictureFormat.Page].height}
                layout="responsive"
              />
            </Link>
          </div>
        )}
        {target && (
          <div className={classes.button}>
            <Button
              alternate={true}
              href={resolveUrl(target)}
              onClickHandler={() => GtmFireEvent(GtmCategory.CTAClick, GtmAction.UniverseSpotlight, label)}
            >
              {label ?? 'Découvrir'}
            </Button>
          </div>
        )}
        <div className={classes.entries}>
          <RowList useAlternateStyle={true}>
            {items.map((item) => {
              return (
                <CardItem
                  key={item.slug}
                  title={item.name}
                  subtitle={item.type.name}
                  picture={item.image}
                  href={`/encyclopedie/${item.slug}`}
                  useAlternateStyle={true}
                  allowPictureScale={false}
                />
              );
            })}
          </RowList>
        </div>
      </section>
    </Gradient>
  );
}

export default Spotlight;
