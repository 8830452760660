import classes from './ChronologyDateItem.module.css';

interface Props {
  date: string;
}

function ChronologyDateItem(props: Props) {
  const { date } = props;

  return (
    <li className={classes.item}>
      <span className={classes.timeline}>
        <span className={classes.dot}></span>
      </span>
      <span className={classes.content}>{date}</span>
    </li>
  );
}

export default ChronologyDateItem;
