import { PictureStructure } from '@/types/documents';
import { toCleanedTextFormat, toValidBlockquoteFormat, toValidImagesFormat, toValidLinkFormat } from '@/utils/markdown';

const useFormatedContent = (content: string, pictures: Array<PictureStructure>) => {
  let formattedContent = content;

  // Clean is done first, to enable next formating to add custom formats
  formattedContent = toCleanedTextFormat(formattedContent);

  formattedContent = toValidBlockquoteFormat(formattedContent);
  formattedContent = toValidLinkFormat(formattedContent);
  formattedContent = toValidImagesFormat(formattedContent, pictures);

  return formattedContent;
};

export default useFormatedContent;
