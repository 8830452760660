import NoPicture from '@/components/ui/Picture/NoPicture';
import { Picture, PictureFormat, PictureRatio } from '@/types/model';
import { generateUrl } from '@/utils/pictureUrlGenetator';
import classNames from 'classnames';
import Image from 'next/legacy/image';
import Link from 'next/link';
import { ReactNode } from 'react';
import classes from './CardItem.module.css';

type Props = {
  title: string;
  href: string;
  subtitle?: string;
  pictureLabel?: ReactNode;
  picture?: Picture;
  pictureFormat?: PictureFormat;
  pictureRatio?: PictureRatio;
  useAlternateStyle?: boolean;
  allowPictureScale?: boolean;
  onClickHandler?: () => void;
};

function CardItem(props: Props) {
  const {
    title,
    href,
    subtitle,
    picture,
    pictureFormat,
    pictureRatio,
    useAlternateStyle,
    allowPictureScale,
    onClickHandler,
  } = props;

  let pictureNode;
  const pictureRatioClass = pictureRatio ?? 'ratio-1-1';
  if (picture) {
    const format = pictureFormat ?? PictureFormat.Square;
    pictureNode = (
      <Image
        src={generateUrl(picture, format)}
        alt={title}
        width={picture.sizes[format].width}
        height={picture.sizes[format].height}
      />
    );
  } else {
    pictureNode = <NoPicture firstLineText={'Aucune image'} secondLineText={'disponible'} />;
  }
  return (
    <div
      className={classNames(classes.container, {
        [classes.containerAlt]: useAlternateStyle === true,
        [classes.fixedPictureSize]: allowPictureScale !== undefined && allowPictureScale !== true,
      })}
    >
      <Link href={href} onClick={onClickHandler ? () => onClickHandler() : null}>
        <div className={classNames(classes.picture, pictureRatioClass)}>{pictureNode}</div>
        <span className={classes.subtitle}>{subtitle}</span>
        <div className={classes.title}>{title}</div>
      </Link>
    </div>
  );
}

export default CardItem;
